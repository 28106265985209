import Image, { ImageProps } from "next/image";
import React from "react";

import { ImageFallback } from "~elements/ImageFallback";

type Props = Omit<ImageProps, "src"> & {
  small?: boolean;
  src: PickType<ImageProps, "src"> | null | undefined;
};

export const NextImage: React.VFC<Props> = ({ src, small, ...props }) => {
  if (src) return <Image {...props} src={src} />;

  return <ImageFallback h={props.height || "full"} small={small} w={props.width || "full"} />;
};
