import { service } from "~lib/i18n/ja/service";

export const admin = {
  "chat-rooms": {
    "[id]": {
      crumb: "お問い合わせ",
      title: "お問い合わせ",
    },
    crumb: "お問い合わせ",
    title: "お問い合わせ",
  },
  dashboard: {
    crumb: "ダッシュボード",
    title: "ダッシュボード",
  },
  inquiries: {
    "[id]": {
      title: `部屋 お問い合わせ`,
    },
    crumb: "部屋 お問い合わせ",
    title: `部屋 お問い合わせ`,
  },
  research: {
    orders: {
      "[id]": {
        title: `${service.research.name} お申し込み詳細`,
      },
      crumb: "お申し込み",
      title: `${service.research.name} お申し込み`,
    },
    reports: {
      "[id]": {
        title: `${service.research.name} レポート編集`,
      },
    },
    rooms: {
      "[id]": {
        title: `${service.research.name} 部屋編集`,
      },
    },
    "sample-reports": {
      "[id]": {
        title: `${service.research.name} サンプルレポート`,
      },
      crumb: "サンプルレポート",
      title: `${service.research.name} サンプルレポート`,
    },
    title: service.research.name,
  },
  room: {
    crumb: "部屋登録",
    title: "部屋登録",
  },
  title: "管理者ログイン",
  titleSuffix: "ROOMiT 管理画面",
  users: {
    "[id]": {
      crumb: "ユーザー詳細",
      title: "ユーザー詳細",
    },
    crumb: "ユーザー",
    title: "ユーザー",
  },
  viewing: {
    orders: {
      "[code]": {
        report: {
          "[roomCode]": {
            edit: {
              title: "内見代行サービス レポート編集",
            },
            title: "内見代行サービス レポート",
          },
        },
        title: "内見代行サービス お申し込み詳細",
      },
      crumb: "お申し込み",
      title: "内見代行サービス お申し込み",
    },
    title: "内見代行サービス",
  },
};
