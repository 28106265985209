import { useRouter } from "next/router";
import nProgress from "nprogress";
import React from "react";

type Props = {};

export const ProgressBar: React.VFC<Props> = () => {
  const router = useRouter();

  const onRouteChangeStart = (path: string, { shallow }: any) => {
    if (!shallow) nProgress.start();
  };

  const onRouteChangeComplete = () => {
    nProgress.done();
  };

  React.useEffect(() => {
    router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router.events]);

  return null;
};
