import { BoxProps, Center, Input, FormControl, Button } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";

import { Card } from "~elements/Card";
import { useConfirm } from "~hooks/useConfirm";

type Props = {};

export const SimpleAuth: React.VFC<Props & BoxProps> = ({ ...props }) => {
  const [password, setPassword] = React.useState("");
  const router = useRouter();
  const confirm = useConfirm();

  const onSubmit = () => {
    if (password === process.env.NEXT_PUBLIC_SIMPLE_AUTH) {
      localStorage.setItem("roomit-simple-auth", password);
      router.reload();
    } else {
      confirm("パスワードが違います", { okOnly: true });
    }
  };

  return (
    <Center w="100%" {...props}>
      <Card maxW="lg" w="100%">
        <FormControl mt={4}>
          <Input
            name="simple-auth"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="パスワード"
            type="password"
            value={password}
          />
        </FormControl>

        <Button mt={4} onClick={onSubmit} w="100%">
          ログイン
        </Button>
      </Card>
    </Center>
  );
};
