import { Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import { Text, Box, Flex, Input, ModalBody, useDisclosure, chakra, Alert, Center, BoxProps } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React from "react";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { LinkList } from "~compositions/LinkList";
import { NextImage } from "~elements/NextImage";
import { pagesPath } from "~generated/$path";
import { isMatchStation } from "~lib/index";
import { stations } from "~lib/stations";

type Props = {
  noLabel?: boolean;
};

export const BlogSearch: React.VFC<Props & BoxProps> = ({ noLabel = false, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [query, setQuery] = React.useState("");
  const [results, setResults] = React.useState<typeof stations[0][]>([]);
  const router = useRouter();

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (query === "") {
      setResults(stations.slice(0, 20));
    } else {
      setResults(stations.filter((station) => isMatchStation(station, query)).slice(0, 20));
    }
  }, [query]);

  React.useEffect(() => {
    const handleRouteChange = () => {
      onClose();
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [onClose, router]);

  return (
    <Box {...props}>
      <ChakraButton
        colorScheme="gray"
        leftIcon={noLabel ? undefined : <FontAwesomeIcon icon="search" />}
        onClick={onOpen}
        rounded="full"
        w="full"
      >
        {noLabel ? <FontAwesomeIcon icon="search" /> : "駅名で検索"}
      </ChakraButton>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="3xl">
        <ModalOverlay />
        <ModalContent bg="gray.50">
          <ModalHeader alignItems="center" display="flex">
            <Input
              autoComplete="off"
              bg="white"
              name="query"
              onChange={(event) => setQuery(event.target.value)}
              placeholder="駅名で検索"
              ref={inputRef}
              type="search"
            />
            <ChakraButton ml={4} onClick={onClose} variant="link">
              閉じる
            </ChakraButton>
          </ModalHeader>
          <ModalBody>
            <LinkList>
              {results.map((station) => {
                return (
                  <LinkList.Item href={pagesPath.blog.stations._id(station.id).$url()} key={station.id}>
                    <Flex>
                      <Center flexShrink={0} pr={4}>
                        <NextImage height={(50 / 4) * 3} src={station.images[0]} width={50} />
                      </Center>

                      <Box flexGrow={1} overflow="hidden">
                        <Box fontWeight="bold">{station.name}</Box>
                        <Text color="muted" fontSize="xs" isTruncated>
                          {station.railwayLines.map((line) => (
                            <chakra.span key={line.id} mr={2}>
                              {line.name}
                            </chakra.span>
                          ))}
                        </Text>
                      </Box>
                    </Flex>
                  </LinkList.Item>
                );
              })}
            </LinkList>

            {results.length === 0 && (
              <Alert colorScheme="yellow" mb={4}>
                見つかりませんでした
              </Alert>
            )}
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </Box>
  );
};
