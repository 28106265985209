import { UrlObject } from "url";

import { Img, Box, Link, Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ChakraLink } from "~/components/alias/ChakraLink";
import { NextLink } from "~/components/alias/NextLink";
import { pagesPath } from "~generated/$path";

const LinkItem: React.FC<{ href: UrlObject }> = ({ children, href }) => {
  return (
    <NextLink href={href}>
      <Link>{children}</Link>
    </NextLink>
  );
};

export const BlogLayoutFooter: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Box as="footer" bgColor="primary" p={4}>
      <Flex>
        <NextLink href="/" passHref>
          <ChakraLink>
            <Img htmlHeight={36} htmlWidth={154} src="/logo_white.svg" />
          </ChakraLink>
        </NextLink>
      </Flex>

      <SimpleGrid columns={{ md: 4 }} gap={6} mt={6}>
        <Stack spacing={6}>
          <LinkItem href={pagesPath.blog.$url()}>{t("page.blog.title")}</LinkItem>
          <LinkItem href={pagesPath.blog.stations.$url()}>{t("page.blog.stations.title")}</LinkItem>
          {/* <LinkItem href={pagesPath.blog.articles.$url()}>{t("page.blog.articles.title")}</LinkItem> */}
        </Stack>
      </SimpleGrid>
    </Box>
  );
};
