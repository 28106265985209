import { Badge, BoxProps } from "@chakra-ui/layout";
import React from "react";

import { NextLink } from "~/components/alias/NextLink";
import { pagesPath } from "~generated/$path";
import { BlogTag as BlogTagType } from "~types/blog";

type Props = {
  tag: BlogTagType;
  withLink?: boolean;
};

export const BlogTag: React.VFC<Props & BoxProps> = ({ tag, withLink = false, ...props }) => {
  const render = () => (
    <Badge as={withLink ? "a" : "span"} key={tag.id} size="small" {...props}>
      {tag.name}
    </Badge>
  );

  return withLink ? (
    <NextLink href={pagesPath.blog.articles.tags._tag(tag.id).page._page(1).$url()} passHref>
      {render()}
    </NextLink>
  ) : (
    render()
  );
};
