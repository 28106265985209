import { createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";

import { User } from "~/generated/graphql";
import { setAccessToken } from "~lib/auth";

export type AuthState = {
  user: User | null | undefined;
};

export const initialState: AuthState = {
  user: undefined,
};

const slice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      Sentry.setUser({ id: state.user ? String(state.user.id) : undefined });
      setAccessToken(state.user?.accessToken);
    },
  },
});

export const { setUser } = slice.actions;
export default slice.reducer;
