export const service = {
  research: {
    name: "わがままお部屋探しサービス",
    nameShort: "お部屋探しサービス",
    nameVeryShort: "お部屋探し",
  },
  viewing: {
    name: "内見代行サービス",
    nameShort: "内見代行サービス",
    nameVeryShort: "内見代行",
  },
};
