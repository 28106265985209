import { ApolloProvider } from "@apollo/client";
import { Box, Container, Flex, Heading } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";

import { PageTransition } from "~compositions/PageTransition";
import { Spinner } from "~elements/Spinner";
import apolloClient from "~graphql/client";
import { useSession } from "~hooks/useSession";
import { FrontLayoutFooter } from "~layouts/front/FrontLayoutFooter";
import { FrontLayoutHeader } from "~layouts/front/FrontLayoutHeader";
import { FrontLayoutMyMenu } from "~layouts/front/FrontLayoutMyMenu";
import { usePageTitle } from "~lib/headUtils";

export const FrontLayout: React.FC = ({ children }) => {
  const { pathname, replace } = useRouter();
  const { user } = useSession();
  const pageTitle = usePageTitle();

  const isMy = pathname.startsWith("/my");

  React.useEffect(() => {
    if (isMy && user === null) {
      replace("/");
    }
  }, [isMy, replace, user]);

  if (isMy && !user) return <Spinner centered />;

  return (
    <ApolloProvider client={apolloClient}>
      <Flex direction="column" flexGrow={1} minH="100vh" w="full">
        <FrontLayoutHeader />

        <Box as="main" display="flex" flexDirection="column" flexGrow={1}>
          {isMy && (
            <>
              <FrontLayoutMyMenu />
              <Container maxW="container.lg" py={8}>
                <Heading as="h1" mb={4} size="h1">
                  {pageTitle}
                </Heading>
                <PageTransition>{children}</PageTransition>
              </Container>
            </>
          )}
          {!isMy && <PageTransition>{children}</PageTransition>}
        </Box>
        <FrontLayoutFooter />
      </Flex>
    </ApolloProvider>
  );
};
