import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import ScrollHintBase from "scroll-hint";
import { v4 } from "uuid";

export const ScrollHint: React.FC<BoxProps> = ({ children, ...props }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [uuid] = React.useState(`scroll-hint-${v4()}`);

  React.useEffect(() => {
    if (!ref.current) return;

    ref.current.classList.add(uuid);
    setTimeout(
      () =>
        new ScrollHintBase(`.${uuid}`, {
          i18n: {
            scrollable: "スクロールできます",
          },
          offset: 0,
        })
    );
  }, [ref, uuid]);

  return (
    <Box ref={ref} {...props}>
      {children}
    </Box>
  );
};
