import { Box, BoxProps } from "@chakra-ui/react";
import { faFileAlt, faShoppingCart, faTachometerAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

import { pagesPath } from "~generated/$path";
import { AdminLayoutDrawerItem } from "~layouts/admin/AdminLayoutDrawerItem";

export const AdminLayoutDrawer: React.VFC<BoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box as="nav" {...props}>
      <AdminLayoutDrawerItem
        href={pagesPath.admin.dashboard.$url()}
        icon={<FontAwesomeIcon icon={faTachometerAlt} />}
        mt={4}
      >
        {t("page.admin.dashboard.crumb")}
      </AdminLayoutDrawerItem>

      <Box color="gray.100" fontSize="sm" fontWeight="bold" mb={1} mt={8} px={2}>
        {t("service.research.nameShort")}
      </Box>
      <AdminLayoutDrawerItem
        href={pagesPath.admin.research.orders.$url()}
        icon={<FontAwesomeIcon fixedWidth icon={faShoppingCart} />}
      >
        {t("page.admin.research.orders.crumb")}
      </AdminLayoutDrawerItem>

      <AdminLayoutDrawerItem
        href={pagesPath.admin.research.sample_reports.$url()}
        icon={<FontAwesomeIcon fixedWidth icon={faFileAlt} />}
      >
        {t("page.admin.research.sample-reports.crumb")}
      </AdminLayoutDrawerItem>

      {/* <AdminLayoutDrawerItem
        href={pagesPath.admin.research.inquiries.$url()}
        icon={<FontAwesomeIcon fixedWidth icon="comment-dots" />}
      >
        {t("page.admin.research.inquiries.crumb")}
      </AdminLayoutDrawerItem>

      <Box color="gray.100" fontSize="sm" fontWeight="bold" mb={1} mt={8} px={2}>
        {t("service.viewing.nameShort")}
      </Box>
      <AdminLayoutDrawerItem
        href={pagesPath.admin.viewing.orders.$url()}
        icon={<FontAwesomeIcon fixedWidth icon="shopping-cart" />}
      >
        {t("page.admin.viewing.orders.crumb")}
      </AdminLayoutDrawerItem> */}

      <AdminLayoutDrawerItem
        href={pagesPath.admin.inquiries.$url()}
        icon={<FontAwesomeIcon fixedWidth icon="comment-dots" />}
        mt={6}
      >
        {t("page.admin.inquiries.crumb")}
      </AdminLayoutDrawerItem>

      <AdminLayoutDrawerItem
        href={pagesPath.admin.chat_rooms.$url()}
        icon={<FontAwesomeIcon fixedWidth icon="envelope-open" />}
      >
        {t("page.admin.chat-rooms.crumb")}
      </AdminLayoutDrawerItem>

      <AdminLayoutDrawerItem href={pagesPath.admin.users.$url()} icon={<FontAwesomeIcon fixedWidth icon={faUser} />}>
        {t("page.admin.users.crumb")}
      </AdminLayoutDrawerItem>
    </Box>
  );
};
