import { UrlObject } from "url";

import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

import { ChakraLink } from "~/components/alias/ChakraLink";
import { NextLink } from "~/components/alias/NextLink";

type Props = {
  href: string | UrlObject;
};

export const Banner: React.FC<Props & BoxProps> = ({ children, href, ...props }) => {
  return (
    <NextLink href={href} passHref>
      <ChakraLink display="block" mx="auto" style={{ height: "280px", width: "280px" }}>
        <Box overflow="hidden" rounded="xl" {...props} _hover={{ opacity: 0.7 }}>
          {children}
        </Box>
      </ChakraLink>
    </NextLink>
  );
};
