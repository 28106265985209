import { UrlObject } from "url";

import { Img, Box, Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";

import { ChakraLink } from "~/components/alias/ChakraLink";
import { NextLink } from "~/components/alias/NextLink";
import { SetToken } from "~/components/debug/SetToken";
import { pagesPath } from "~generated/$path";

const LinkItem: React.FC<{ href: UrlObject | string }> = ({ children, href }) => {
  return (
    <NextLink href={href} passHref>
      <ChakraLink>{children}</ChakraLink>
    </NextLink>
  );
};

export const FrontLayoutFooter: React.VFC = () => {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  if (pathname === "/contact") return null;

  return (
    <Box as="footer" bgColor="primary" p={4}>
      <Flex>
        <NextLink href="/" passHref>
          <ChakraLink>
            <Img htmlHeight={36} htmlWidth={154} src="/logo_white.svg" />
          </ChakraLink>
        </NextLink>
      </Flex>

      <SimpleGrid columns={{ md: 4 }} gap={6} mt={6}>
        <Stack spacing={6}>
          <LinkItem href={pagesPath.tokyo.$url()}>{t("page.tokyo.title")}</LinkItem>

          <LinkItem href={pagesPath.research.$url()}>{t("page.research.title")}</LinkItem>

          {/* <LinkItem href={pagesPath.viewing.$url()}>{t("page.viewing.title")}</LinkItem> */}
          <LinkItem href={pagesPath.faq.$url()}>{t("page.faq.title")}</LinkItem>
          <LinkItem href={pagesPath.contact.$url()}>{t("page.contact.title")}</LinkItem>
        </Stack>

        {/* <Stack spacing={6}>
          <LinkItem href={pagesPath.blog.$url()}>{t("page.blog.title")}</LinkItem>
          <LinkItem href={pagesPath.blog.stations.$url()}>{t("page.blog.stations.title")}</LinkItem>
          <LinkItem href={pagesPath.blog.articles.$url()}>{t("page.blog.articles.title")}</LinkItem>
        </Stack> */}

        <Stack spacing={6}>
          <LinkItem href={pagesPath.info.terms_of_service.$url()}>{t("page.info.terms-of-service.title")}</LinkItem>
          <LinkItem href={pagesPath.info.privacy_policy.$url()}>{t("page.info.privacy-policy.title")}</LinkItem>
          <LinkItem href={pagesPath.info.law.$url()}>{t("page.info.law.title")}</LinkItem>
          <ChakraLink href="https://my-trend.co.jp" target="_blank">
            会社概要
          </ChakraLink>
        </Stack>
      </SimpleGrid>

      {process.env.NODE_ENV === "development" && <SetToken />}
    </Box>
  );
};
