import stationData from "~generated/stations.json";

export const stations = stationData.map((data) => ({
  ...data,
  images: data.images.map((image) => process.env.NEXT_PUBLIC_S3_BASE_URL + image),
}));

export const popularStationIds = [
  1131104, // 吉祥寺
  2600107, // 自由が丘
  2400106, // 明大前
  1131217, // 荻窪
  2200106, // 練馬
  1132005, // 北千住
  2600307, // 二子玉川
  1131903, // 赤羽
  2600303, // 三軒茶屋
  2600503, // 戸越銀座
];

export type BlogParentStation = typeof stationData[0];

export const popularStations = popularStationIds.map((id) =>
  stations.find((station) => station.id === id)
) as BlogParentStation[];
