import * as Lockr from "lockr";

import { isServer } from "~/lib/index";

const KEY = "accessToken";

if (!isServer()) window.Lockr = Lockr;

export const setAccessToken = (accessToken: string | undefined | null) => {
  accessToken ? Lockr.set(KEY, accessToken) : Lockr.rm(KEY);
};

export const getAccessToken = (): string | null => {
  if (isServer()) return null;

  return Lockr.get(KEY, null);
};
