import { BoxProps, Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/react";
import React from "react";

type Props = {
  centered?: boolean;
  subTitle?: string;
  title: React.ReactElement;
};

export const WidgetTitle: React.VFC<Props & Omit<BoxProps, "title">> = ({ title, subTitle, ...props }) => {
  return (
    <Flex flexDirection="column" {...props}>
      <div>{title}</div>
      {subTitle && (
        <Box color="muted" fontSize="sm" mt={1}>
          {subTitle}
        </Box>
      )}
    </Flex>
  );
};
