import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React from "react";

import { AdminLayoutAccountMenu } from "~layouts/admin/AdminLayoutAccountMenu";
import { AdminLayoutDrawer } from "~layouts/admin/AdminLayoutDrawer";
import { AdminLayoutLogo } from "~layouts/admin/AdminLayoutLogo";
import { pathnameToTitle } from "~lib/headUtils";

export const AdminLayoutHeader: React.VFC = () => {
  const { pathname, events } = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    events.on("routeChangeStart", onClose);

    return () => {
      events.off("routeChangeStart", onClose);
    };
  }, [events, onClose]);

  return (
    <Box
      alignItems="center"
      bg="white"
      borderBottomWidth={1}
      display="flex"
      fontWeight="bold"
      h={12}
      pl={{ base: 0, sm: 4 }}
      pos="sticky"
      pr={4}
      top={0}
      zIndex="banner"
    >
      <IconButton
        aria-label=""
        colorScheme="black"
        display={{ base: "inline-flex", sm: "none" }}
        onClick={onOpen}
        variant="ghost"
      >
        <FontAwesomeIcon icon="bars" />
      </IconButton>

      {pathnameToTitle(pathname)}

      <AdminLayoutAccountMenu />

      <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="xs">
        <DrawerOverlay />
        <DrawerContent bg="primary">
          <DrawerCloseButton />
          <AdminLayoutLogo />

          <DrawerBody px={2}>
            <AdminLayoutDrawer display="block" />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
