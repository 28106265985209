import { UrlObject } from "url";

import { Box, BoxProps, chakra, Stack, StackDivider } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { NextLink } from "~/components/alias/NextLink";

type Props = {};

export const LinkList: React.VFC<Props & BoxProps> & { Item: typeof LinkListItem } = ({ children, ...props }) => {
  return (
    <Stack bg="white" boxShadow="base" direction="column" divider={<StackDivider />} spacing={0} {...props}>
      {children}
    </Stack>
  );
};

const LinkListItem: React.VFC<{ href: UrlObject | string; isDisabled?: boolean; isExternal?: boolean } & BoxProps> = ({
  isDisabled = false,
  children,
  href,
  isExternal,
  ...props
}) => {
  const render = () => (
    <Box
      _hover={{ background: isDisabled ? "white" : "gray.100" }}
      alignItems="center"
      as="a"
      display="flex"
      p={4}
      target={isExternal ? "_blank" : "_self"}
      {...props}
    >
      <Box flexGrow={1} h="full" overflow="hidden" pr={2}>
        {children}
      </Box>
      {!isDisabled && (
        <chakra.span pl={4}>
          <FontAwesomeIcon icon="chevron-right" />
        </chakra.span>
      )}
    </Box>
  );

  if (isDisabled) return render();

  return (
    <NextLink href={href} passHref>
      {render()}
    </NextLink>
  );
};

LinkList.Item = LinkListItem;
