import React from "react";

export const useSimpleAuth = () => {
  const [simpleAuthed, setSimpleAuthed] = React.useState<boolean | undefined>(
    process.env.NEXT_PUBLIC_SIMPLE_AUTH ? undefined : true
  );

  React.useEffect(() => {
    if (!process.env.NEXT_PUBLIC_SIMPLE_AUTH) return;

    setSimpleAuthed(process.env.NEXT_PUBLIC_SIMPLE_AUTH === localStorage["roomit-simple-auth"]);
  }, []);

  return { simpleAuthed };
};
