import { Center, Spinner as BaseSpinner, SpinnerProps } from "@chakra-ui/react";
import React from "react";

type Props = {
  centered?: boolean;
};

export const Spinner: React.VFC<Props & SpinnerProps> = ({ centered = false, size = "xl", ...props }) => {
  return (
    <Center minH={centered ? "50vh" : "auto"} {...props}>
      <BaseSpinner color="brand" size={size} thickness="2px" />
    </Center>
  );
};
