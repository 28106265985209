import { format, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";
import { toKatakana } from "jaco";
import jpPrefecture from "jp-prefecture";

import { Station } from "~generated/graphql";

export const formatTime = (date: Date | string | null, timeFormat?: string | undefined) => {
  if (!date) return "";
  return format(typeof date === "string" ? parseISO(date) : date, timeFormat || "yyyy年MM月dd日(E) HH:mm:ss", {
    locale: ja,
  });
};

export const formatDate = (date: Date | null, dateFormat?: string | undefined) => {
  if (!date) return "";
  return format(date, dateFormat || "yyyy年MM月dd日(E)", { locale: ja });
};

export const formatPostalCode = (value: string) => {
  const splitted = value.split("");
  splitted.splice(3, 0, "-");
  return splitted;
};

export const isClient = () => typeof window !== "undefined";
export const isServer = () => !isClient();

export const isMatchStation = (station: Pick<Station, "name" | "kana" | "roman">, query: string) => {
  return (
    station.name.includes(query) ||
    station.kana.includes(toKatakana(query)) ||
    station.roman.toLowerCase().startsWith(query.toLowerCase())
  );
};

export const panoramaViewerHref = (url: string) => `${location.origin}/panorama-viewer?src=${encodeURIComponent(url)}`;

export const isValidationError = (e: any) => {
  return !!e.graphQLErrors?.[0]?.extensions?.validation;
};

export const isUnauthorizedError = (e: any) => {
  return !!e.graphQLErrors?.[0]?.extensions?.unauthorized;
};

export const isNotFoundError = (e: any) => {
  return !!e.graphQLErrors?.[0]?.extensions?.notFound;
};

export const feeNames = [
  "rent",
  "managementFee",
  "deposit",
  "rewards",
  "commission",
  "advanceRent",
  "guarantorFee",
  "fireInsuranceFee",
  "keyExchange",
  "cleaning",
  "otherFee",
] as const;

export const prefectureOptions: Array<{ label: string; value: string }> = jpPrefecture
  .getAll("pref", ["id", "name"])
  .map((pref: { name: string }) => ({ label: pref.name, value: pref.name }));

export const withTax = (yen: number) => {
  return Math.floor(yen * 1.1);
};
