import { UrlObject } from "url";

import { Box, Flex } from "@chakra-ui/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React from "react";

import { NextLink } from "~/components/alias/NextLink";
import { useSelector } from "~/store";

export type BreadcrumbItem = {
  href?: UrlObject | string | undefined;
  label: React.ReactChild | string;
};

export const Breadcrumbs: React.VFC = () => {
  const router = useRouter();
  const ref = React.useRef<HTMLDivElement>(null);
  const { items } = useSelector((state) => state.breadcrumbs);

  React.useEffect(() => {
    const handleRouteChange = () => {
      ref.current?.scrollTo(100000, 0);
    };
    router?.events?.on("routeChangeComplete", handleRouteChange);
    handleRouteChange();

    return () => {
      router?.events?.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  const renderItem = ({ last = false, item }: { item: BreadcrumbItem; last?: boolean }) => {
    return (
      <Box
        _after={{
          borderColor: "transparent transparent transparent #fff",
          borderWidth: "22px 0px 22px 12px",
          content: "''",
          display: last ? "none" : "block",
          position: "absolute",
          right: "1px",
          top: 0,
        }}
        _before={{
          borderColor: "transparent transparent transparent var(--chakra-colors-gray-300)",
          borderWidth: "22px 0px 22px 12px",
          content: "''",
          display: last ? "none" : "block",
          position: "absolute",
          right: 0,
          top: 0,
        }}
        _hover={{
          textDecoration: "underline",
        }}
        as="a"
        display="inline-block"
        flexShrink={0}
        pl="11px"
        pos="relative"
        pr="22px"
      >
        {item.label}
      </Box>
    );
  };

  if (items === null) return null;

  return (
    <Flex bg="white" borderBottomWidth={1} h={10} lineHeight={10} overflowX="scroll" ref={ref}>
      <NextLink href="/" passHref>
        {renderItem({ item: { label: <FontAwesomeIcon icon="home" /> } })}
      </NextLink>

      {items.map((item, index) => {
        const last = items.length === index + 1;
        if (item.href === undefined) return renderItem({ item, last });

        return (
          <NextLink href={item.href} key={index} passHref>
            {renderItem({ item, last })}
          </NextLink>
        );
      })}
    </Flex>
  );
};
