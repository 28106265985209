/* eslint-disable */
// prettier-ignore
export const pagesPath = {
  $404: {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  $500: {
    $url: (url?: { hash?: string }) => ({ pathname: '/500' as const, hash: url?.hash })
  },
  admin: {
    chat_rooms: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/chat-rooms/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/chat-rooms' as const, hash: url?.hash })
    },
    dashboard: {
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/dashboard' as const, hash: url?.hash })
    },
    inquiries: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/inquiries/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/inquiries' as const, hash: url?.hash })
    },
    research: {
      orders: {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/admin/research/orders/[id]' as const, query: { id }, hash: url?.hash })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/research/orders' as const, hash: url?.hash })
      },
      reports: {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/admin/research/reports/[id]' as const, query: { id }, hash: url?.hash })
        })
      },
      rooms: {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/admin/research/rooms/[id]' as const, query: { id }, hash: url?.hash })
        })
      },
      sample_reports: {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/admin/research/sample-reports/[id]' as const, query: { id }, hash: url?.hash })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/research/sample-reports' as const, hash: url?.hash })
      }
    },
    users: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/users/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/users' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/admin' as const, hash: url?.hash })
  },
  blog: {
    articles: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/blog/articles/[id]' as const, query: { id }, hash: url?.hash })
      }),
      page: {
        _page: (page: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/blog/articles/page/[page]' as const, query: { page }, hash: url?.hash })
        })
      },
      preview: {
        $url: (url?: { hash?: string }) => ({ pathname: '/blog/articles/preview' as const, hash: url?.hash })
      },
      tags: {
        _tag: (tag: string | number) => ({
          page: {
            _page: (page: string | number) => ({
              $url: (url?: { hash?: string }) => ({ pathname: '/blog/articles/tags/[tag]/page/[page]' as const, query: { tag, page }, hash: url?.hash })
            })
          }
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/blog/articles/tags' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/blog/articles' as const, hash: url?.hash })
    },
    stations: {
      _id: (id: string | number) => ({
        reviews: {
          _reviewId: (reviewId: string | number) => ({
            $url: (url?: { hash?: string }) => ({ pathname: '/blog/stations/[id]/reviews/[reviewId]' as const, query: { id, reviewId }, hash: url?.hash })
          }),
          page: {
            _page: (page: string | number) => ({
              $url: (url?: { hash?: string }) => ({ pathname: '/blog/stations/[id]/reviews/page/[page]' as const, query: { id, page }, hash: url?.hash })
            })
          }
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/blog/stations/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/blog/stations' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/blog' as const, hash: url?.hash })
  },
  contact: {
    login: {
      $url: (url?: { hash?: string }) => ({ pathname: '/contact/login' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/contact' as const, hash: url?.hash })
  },
  debug: {
    $url: (url?: { hash?: string }) => ({ pathname: '/debug' as const, hash: url?.hash })
  },
  estimation: {
    $url: (url?: { hash?: string }) => ({ pathname: '/estimation' as const, hash: url?.hash })
  },
  faq: {
    $url: (url?: { hash?: string }) => ({ pathname: '/faq' as const, hash: url?.hash })
  },
  info: {
    law: {
      $url: (url?: { hash?: string }) => ({ pathname: '/info/law' as const, hash: url?.hash })
    },
    privacy_policy: {
      $url: (url?: { hash?: string }) => ({ pathname: '/info/privacy-policy' as const, hash: url?.hash })
    },
    terms_of_service: {
      $url: (url?: { hash?: string }) => ({ pathname: '/info/terms-of-service' as const, hash: url?.hash })
    }
  },
  login: {
    $url: (url?: { hash?: string }) => ({ pathname: '/login' as const, hash: url?.hash })
  },
  my: {
    research: {
      orders: {
        _code: (code: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/my/research/orders/[code]' as const, query: { code }, hash: url?.hash })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/my/research/orders' as const, hash: url?.hash })
      },
      reports: {
        _code: (code: string | number) => ({
          inquiries: {
            complete: {
              $url: (url?: { hash?: string }) => ({ pathname: '/my/research/reports/[code]/inquiries/complete' as const, query: { code }, hash: url?.hash })
            },
            $url: (url?: { hash?: string }) => ({ pathname: '/my/research/reports/[code]/inquiries' as const, query: { code }, hash: url?.hash })
          },
          $url: (url?: { hash?: string }) => ({ pathname: '/my/research/reports/[code]' as const, query: { code }, hash: url?.hash })
        })
      },
      rooms: {
        _code: (code: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/my/research/rooms/[code]' as const, query: { code }, hash: url?.hash })
        })
      }
    },
    setting: {
      email: {
        $url: (url?: { hash?: string }) => ({ pathname: '/my/setting/email' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/my/setting' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/my' as const, hash: url?.hash })
  },
  panorama_viewer: {
    $url: (url?: { hash?: string }) => ({ pathname: '/panorama-viewer' as const, hash: url?.hash })
  },
  research: {
    order: {
      complete: {
        $url: (url?: { hash?: string }) => ({ pathname: '/research/order/complete' as const, hash: url?.hash })
      },
      login: {
        $url: (url?: { hash?: string }) => ({ pathname: '/research/order/login' as const, hash: url?.hash })
      },
      payment: {
        paypay: {
          callback: {
            $url: (url?: { hash?: string }) => ({ pathname: '/research/order/payment/paypay/callback' as const, hash: url?.hash })
          },
          resume: {
            $url: (url?: { hash?: string }) => ({ pathname: '/research/order/payment/paypay/resume' as const, hash: url?.hash })
          }
        }
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/research/order' as const, hash: url?.hash })
    },
    sample_reports: {
      _code: (code: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/research/sample-reports/[code]' as const, query: { code }, hash: url?.hash })
      }),
      rooms: {
        _code: (code: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/research/sample-reports/rooms/[code]' as const, query: { code }, hash: url?.hash })
        })
      }
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/research' as const, hash: url?.hash })
  },
  tokyo: {
    $url: (url?: { hash?: string }) => ({ pathname: '/tokyo' as const, hash: url?.hash })
  },
  verify_unconfirmed_email_token: {
    $url: (url?: { hash?: string }) => ({ pathname: '/verify-unconfirmed-email-token' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

// prettier-ignore
export type PagesPath = typeof pagesPath

// prettier-ignore
export const staticPath = {
  admin: {
    report_png: '/admin/report.png'
  },
  android_chrome_192x192_png: '/android-chrome-192x192.png',
  android_chrome_512x512_png: '/android-chrome-512x512.png',
  apple_touch_icon_png: '/apple-touch-icon.png',
  blog: {
    badge_svg: '/blog/badge.svg',
    circle_yen_svg: '/blog/circle-yen.svg',
    face_svg: '/blog/face.svg',
    first_view_jpg: '/blog/first_view.jpg',
    first_view_catch_copy_svg: '/blog/first_view_catch_copy.svg',
    first_view_text_svg: '/blog/first_view_text.svg',
    information_svg: '/blog/information.svg',
    logo_svg: '/blog/logo.svg',
    no1_png: '/blog/no1.png',
    no2_png: '/blog/no2.png',
    no3_png: '/blog/no3.png',
    ogp_jpg: '/blog/ogp.jpg',
    ranking_svg: '/blog/ranking.svg',
    search_svg: '/blog/search.svg',
    service_svg: '/blog/service.svg'
  },
  browserconfig_xml: '/browserconfig.xml',
  favicon_16x16_png: '/favicon-16x16.png',
  favicon_32x32_png: '/favicon-32x32.png',
  favicon_ico: '/favicon.ico',
  icon: {
    cancel_png: '/icon/cancel.png',
    color_picker_png: '/icon/color-picker.png',
    icon_360_view_png: '/icon/icon_360_view.png',
    research_svg: '/icon/research.svg',
    viewing_svg: '/icon/viewing.svg'
  },
  icon_svg: '/icon.svg',
  illustration: {
    chat_svg: '/illustration/chat.svg',
    error_svg: '/illustration/error.svg',
    in_the_work_svg: '/illustration/in-the-work.svg',
    no_data_svg: '/illustration/no-data.svg',
    not_found_svg: '/illustration/not_found.svg'
  },
  logo_svg: '/logo.svg',
  logo_white_svg: '/logo_white.svg',
  logo_with_tagline_svg: '/logo_with_tagline.svg',
  logo_with_tagline_white_png: '/logo_with_tagline_white.png',
  mstile_150x150_png: '/mstile-150x150.png',
  no_image_s_svg: '/no-image-s.svg',
  no_image_svg: '/no-image.svg',
  ogp_jpg: '/ogp.jpg',
  order: {
    complete_png: '/order/complete.png',
    paypay_png: '/order/paypay.png'
  },
  research: {
    banner_jpg: '/research/banner.jpg',
    circumstance_1_svg: '/research/circumstance_1.svg',
    circumstance_2_svg: '/research/circumstance_2.svg',
    circumstance_3_svg: '/research/circumstance_3.svg',
    experience_1_svg: '/research/experience_1.svg',
    experience_2_svg: '/research/experience_2.svg',
    experience_3_svg: '/research/experience_3.svg',
    first_view_jpg: '/research/first_view.jpg',
    free_png: '/research/free.png',
    important_svg: '/research/important.svg',
    ogp_jpg: '/research/ogp.jpg',
    recommended_1_svg: '/research/recommended_1.svg',
    recommended_2_svg: '/research/recommended_2.svg',
    recommended_3_svg: '/research/recommended_3.svg',
    view_point_1_jpg: '/research/view_point_1.jpg',
    view_point_2_jpg: '/research/view_point_2.jpg',
    view_point_3_jpg: '/research/view_point_3.jpg',
    view_point_4_jpg: '/research/view_point_4.jpg'
  },
  safari_pinned_tab_svg: '/safari-pinned-tab.svg',
  sample_report: {
    avatar: {
      $1_svg: '/sample-report/avatar/1.svg',
      $10_svg: '/sample-report/avatar/10.svg',
      $11_svg: '/sample-report/avatar/11.svg',
      $12_svg: '/sample-report/avatar/12.svg',
      $13_svg: '/sample-report/avatar/13.svg',
      $14_svg: '/sample-report/avatar/14.svg',
      $15_svg: '/sample-report/avatar/15.svg',
      $16_svg: '/sample-report/avatar/16.svg',
      $17_svg: '/sample-report/avatar/17.svg',
      $18_svg: '/sample-report/avatar/18.svg',
      $19_svg: '/sample-report/avatar/19.svg',
      $2_svg: '/sample-report/avatar/2.svg',
      $20_svg: '/sample-report/avatar/20.svg',
      $21_svg: '/sample-report/avatar/21.svg',
      $22_svg: '/sample-report/avatar/22.svg',
      $23_svg: '/sample-report/avatar/23.svg',
      $24_svg: '/sample-report/avatar/24.svg',
      $25_svg: '/sample-report/avatar/25.svg',
      $26_svg: '/sample-report/avatar/26.svg',
      $27_svg: '/sample-report/avatar/27.svg',
      $28_svg: '/sample-report/avatar/28.svg',
      $29_svg: '/sample-report/avatar/29.svg',
      $3_svg: '/sample-report/avatar/3.svg',
      $30_svg: '/sample-report/avatar/30.svg',
      $4_svg: '/sample-report/avatar/4.svg',
      $5_svg: '/sample-report/avatar/5.svg',
      $6_svg: '/sample-report/avatar/6.svg',
      $7_svg: '/sample-report/avatar/7.svg',
      $8_svg: '/sample-report/avatar/8.svg',
      $9_svg: '/sample-report/avatar/9.svg'
    },
    drawing_sample_jpg: '/sample-report/drawing_sample.jpg'
  },
  site_webmanifest: '/site.webmanifest',
  sorry_png: '/sorry.png',
  tokyo: {
    banner_jpg: '/tokyo/banner.jpg',
    comic_jpg: '/tokyo/comic.jpg',
    first_view_jpg: '/tokyo/first_view.jpg',
    first_view_text_svg: '/tokyo/first_view_text.svg',
    ogp_jpg: '/tokyo/ogp.jpg',
    recommended1_jpg: '/tokyo/recommended1.jpg',
    recommended2_jpg: '/tokyo/recommended2.jpg',
    recommended3_jpg: '/tokyo/recommended3.jpg',
    research_jpg: '/tokyo/research.jpg',
    research_png: '/tokyo/research.png',
    support1_png: '/tokyo/support1.png',
    support2_png: '/tokyo/support2.png',
    viewing_jpg: '/tokyo/viewing.jpg',
    viewing_png: '/tokyo/viewing.png'
  },
  top: {
    first_view_text_svg: '/top/first_view_text.svg',
    room1_jpg: '/top/room1.jpg',
    room2_jpg: '/top/room2.jpg',
    room3_jpg: '/top/room3.jpg',
    room4_jpg: '/top/room4.jpg',
    room5_jpg: '/top/room5.jpg',
    room6_jpg: '/top/room6.jpg'
  },
  viewing: {
    banner_jpg: '/viewing/banner.jpg',
    chara1_png: '/viewing/chara1.png',
    chara2_png: '/viewing/chara2.png',
    chara3_png: '/viewing/chara3.png',
    chara4_png: '/viewing/chara4.png',
    feature1_jpg: '/viewing/feature1.jpg',
    feature2_jpg: '/viewing/feature2.jpg',
    feature3_jpg: '/viewing/feature3.jpg',
    first_view_jpg: '/viewing/first_view.jpg',
    recommended1_jpg: '/viewing/recommended1.jpg',
    recommended2_jpg: '/viewing/recommended2.jpg',
    recommended3_jpg: '/viewing/recommended3.jpg'
  }
} as const

// prettier-ignore
export type StaticPath = typeof staticPath
