import { BoxProps, Center, chakra } from "@chakra-ui/react";
import React from "react";

import { staticPath } from "~generated/$path";

type Props = {
  small?: boolean;
};

export const ImageFallback: React.VFC<Props & BoxProps> = ({ small, ...props }) => {
  return (
    <Center bg="gray.100" p={1} {...props}>
      <chakra.img src={small ? staticPath.no_image_s_svg : staticPath.no_image_svg} w="full" />
    </Center>
  );
};
