import "@fortawesome/fontawesome-svg-core/styles.css";

import { config, library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBell,
  faBookmark,
  faCamera,
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faClock,
  faCog,
  faCommentDots,
  faCreditCard,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faFile,
  faHome,
  faMapMarkerAlt,
  faPaperclip,
  faPen,
  faPlus,
  faQuestionCircle,
  faRocket,
  faSearch,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faSquare,
  faTachometerAlt,
  faTimes,
  faTrain,
  faTrash,
  faUndo,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

config.autoAddCss = false;

library.add(
  faAngleRight,
  faChevronDown,
  faSearch,
  faHome,
  faClock,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBell,
  faBookmark,
  faCamera,
  faCheckSquare,
  faChevronRight,
  faCog,
  faCommentDots,
  faCreditCard,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faFile,
  faMapMarkerAlt,
  faPaperclip,
  faPen,
  faPlus,
  faQuestionCircle,
  faRocket,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faSquare,
  faTachometerAlt,
  faTimes,
  faTrain,
  faTrash,
  faUndo,
  faUser
);
