import { BoxProps, Center } from "@chakra-ui/react";
import React from "react";

import { ChakraLink } from "~/components/alias/ChakraLink";
import { NextLink } from "~/components/alias/NextLink";
import { NextImage } from "~elements/NextImage";
import { pagesPath } from "~generated/$path";

type Props = {};

export const AdminLayoutLogo: React.VFC<Props & BoxProps> = ({ ...props }) => {
  return (
    <Center borderBottomWidth={1} borderColor="white" h={12} justifyContent="flex-start" px={4} {...props}>
      <NextLink href={pagesPath.admin.dashboard.$url()} passHref>
        <ChakraLink>
          <NextImage height={40} src="/logo_white.svg" width={170} />
        </ChakraLink>
      </NextLink>
    </Center>
  );
};
