import { BoxProps, Flex } from "@chakra-ui/layout";
import { Text, AspectRatio, Box, chakra } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

import { NextLink } from "~/components/alias/NextLink";
import { BlogTags } from "~compositions/blog/BlogTags";
import { NextImage } from "~elements/NextImage";
import { pagesPath } from "~generated/$path";
import { BlogArticle } from "~types/blog";

type Props = {
  article: BlogArticle;
};

export const BlogArticleCard: React.VFC<Props & BoxProps> = ({ article, ...props }) => {
  const { t } = useTranslation();

  return (
    <NextLink href={pagesPath.blog.articles._id(article.id).$url()} key={article.id} passHref>
      <chakra.a _hover={{ opacity: 0.8 }} display="block" h="full">
        <Box bg="white" borderWidth={1} display="flex" flexDirection="column" h="full" w="full" {...props}>
          <AspectRatio ratio={2 / 1} w="full">
            <NextImage layout="fill" objectFit="cover" src={article.mainImage?.url} />
          </AspectRatio>

          <Flex flexDirection="column" flexGrow={1} pb={2} px={2}>
            <Box flexGrow={1} fontWeight="bold" mt={2}>
              <Text noOfLines={3}>{article.title}</Text>
            </Box>

            <BlogTags tags={article.tags} />

            <Flex alignItems="center" color="muted" mt={2}>
              <FontAwesomeIcon icon="clock" />
              <chakra.span fontSize="sm" pl={1}>
                {t("format.date.default", { date: new Date(article.publishedAt) })}
              </chakra.span>
            </Flex>
          </Flex>
        </Box>
      </chakra.a>
    </NextLink>
  );
};
