import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ErrorState = {
  errorMessage: String | null;
};

export const initialState: ErrorState = {
  errorMessage: null,
};

const slice = createSlice({
  initialState,
  name: "error",
  reducers: {
    setErrorMessage(state, action: PayloadAction<string | null>) {
      if (state.errorMessage && action.payload) return;

      switch (action.payload) {
        case ":systemError":
          state.errorMessage = "システムエラーが発生しました";
          break;
        default:
          state.errorMessage = action.payload;
      }
    },
  },
});

export const { setErrorMessage } = slice.actions;
export default slice.reducer;
