import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";

import { getAccessToken } from "~/lib/auth";
import { store } from "~/store";
import { setErrorMessage } from "~/store/error";
import { isNotFoundError, isUnauthorizedError, isValidationError } from "~lib/index";

const ssr = typeof window === "undefined";

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError((error) => {
  console.error(error);
  Sentry.withScope((scope) => {
    scope.setExtra("operationName", error.operation.operationName);
    scope.setExtra("query", error.operation.query.loc?.source?.body?.trim());
    scope.setExtra("variables", JSON.stringify(error.operation.variables, null, "  "));

    if (error.networkError) {
      scope.setExtra("networkError", JSON.stringify(error.networkError, null, "  "));
      store.dispatch(setErrorMessage("通信エラーが発生しました"));
      Sentry.captureMessage(`Network Error: ${error.operation.operationName}`);
    } else {
      scope.setExtra("graphQLErrors", JSON.stringify(error.graphQLErrors, null, "  "));
      scope.setExtra("operation", JSON.stringify(error.operation, null, "  "));
      scope.setExtra("response", JSON.stringify(error.response, null, "  "));

      if (isValidationError(error)) {
        store.dispatch(setErrorMessage(error.graphQLErrors?.[0]?.message || "バリデーションエラー"));
      } else if (isUnauthorizedError(error)) {
        store.dispatch(setErrorMessage("アクセス権がありません"));
      } else if (isNotFoundError(error)) {
        store.dispatch(setErrorMessage("データが見つかりませんでした"));
      } else {
        store.dispatch(setErrorMessage("システムエラーが発生しました"));
        Sentry.captureMessage(`GraphQL Error: ${error.operation.operationName}`);
      }
    }
  });
});

const httpLink = ApolloLink.from([
  authLink,
  errorLink,
  createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
  }),
]);

const cache = new InMemoryCache({
  possibleTypes: {
    Order: ["ResearchOrder", "ViewingOrder"],
    OrderPayment: ["OrderPaymentPayjp"],
    ReportRoom: ["ResearchReportRoom", "ViewingReportRoom"],
  },
});

const createLink = (httpLink: ApolloLink) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { createConsumer } = require("actioncable-js-jwt");
  const cable = createConsumer(process.env.NEXT_PUBLIC_ACTION_CABLE_URL, getAccessToken());

  return ApolloLink.split(
    ({ query: { definitions } }) => {
      return definitions.some(
        (definition) => definition.kind === "OperationDefinition" && definition.operation === "subscription"
      );
    },
    new ActionCableLink({ cable }),
    httpLink
  );
};

const client = new ApolloClient({
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
  },
  link: ssr ? ApolloLink.from([authLink, errorLink, ssr ? httpLink : createLink(httpLink)]) : createLink(httpLink),
});

export default client;
