import { UrlObject } from "url";

import { Box, Container, Stack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { NextLink } from "~/components/alias/NextLink";
import { pagesPath, staticPath } from "~generated/$path";

const MenuItem: React.FC<{ href: UrlObject; icon: React.ReactElement; isCurrent: boolean }> = ({
  href,
  children,
  icon,
  isCurrent,
}) => {
  return (
    <NextLink href={href} passHref>
      <ChakraButton
        alignItems="center"
        as="a"
        colorScheme={isCurrent ? "brand" : "gray"}
        flexDirection={{ sm: "column" }}
        h="auto"
        height={{ base: 16, sm: 24 }}
        iconSpacing={{ base: 4, sm: 0 }}
        justifyContent={{ base: "center", sm: "space-around" }}
        leftIcon={icon}
        py={2}
        w="full"
      >
        <Box pl={{ base: 2, sm: 0 }} textAlign={{ base: "left", sm: "center" }} w="6em">
          {children}
        </Box>
      </ChakraButton>
    </NextLink>
  );
};

export const FrontLayoutMyMenu: React.VFC = () => {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  return (
    <Container display="flex" maxW="container.lg" mt={4} mx="auto">
      <Stack direction={{ base: "column", sm: "row" }} w="full">
        <MenuItem
          href={pagesPath.my.$url()}
          icon={<FontAwesomeIcon fixedWidth icon="user" style={{ height: "40px", width: "40px" }} />}
          isCurrent={pathname === "/my"}
        >
          {t("page.my.crumb")}
        </MenuItem>
        <MenuItem
          href={pagesPath.my.research.orders.$url()}
          icon={<img src={staticPath.icon.research_svg} width="40px" />}
          isCurrent={pathname.startsWith("/my/research")}
        >
          {t("page.my.research.orders.crumb")}
        </MenuItem>
        {/* <MenuItem
          href={pagesPath.my.viewing.orders.$url()}
          icon={<img src={staticPath.icon.viewing_svg} width="40px" />}
          isCurrent={pathname.startsWith("/my/viewing")}
        >
          {t("page.my.viewing.orders.crumb")}
        </MenuItem> */}
        <MenuItem
          href={pagesPath.my.setting.$url()}
          icon={<FontAwesomeIcon fixedWidth icon="cog" size="2x" style={{ height: "40px", width: "40px" }} />}
          isCurrent={pathname.startsWith("/my/setting")}
        >
          {t("page.my.setting.crumb")}
        </MenuItem>
      </Stack>
    </Container>
  );
};
