import { IconButton, Menu, Box, MenuButton, MenuList, Center, Divider } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { LinkList } from "~compositions/LinkList";
import { NotificationListItems } from "~compositions/NotificationListItems";
import { pagesPath } from "~generated/$path";
import { useNotificationSummarySubscription } from "~generated/graphql";
import { useSession } from "~hooks/useSession";

export const FrontLayoutNotifications: React.VFC = () => {
  const { user } = useSession();
  const { data } = useNotificationSummarySubscription({ skip: !user });

  const notifications = data?.notificationSummary?.notifications;
  const unreadCount = data?.notificationSummary?.unreadCount;

  if (!user || unreadCount === undefined) return null;

  return (
    <Menu isLazy>
      {({ onClose }) => (
        <>
          <MenuButton _hover={{ background: "brand.600" }} as={IconButton} color="white" pos="relative" variant="ghost">
            <FontAwesomeIcon icon="bell" />
            {unreadCount > 0 && (
              <Center bg="purple" fontSize="xs" h={5} pos="absolute" right={0} rounded="full" top={0} w={5}>
                {unreadCount}
              </Center>
            )}
          </MenuButton>

          <MenuList onClick={onClose} py={0} rootProps={{ style: { right: 0 } }}>
            <NotificationListItems notifications={notifications} />

            <Divider />

            {notifications && notifications.length > 0 && (
              <LinkList.Item href={pagesPath.my.$url()}>
                <Box flexGrow={1} textAlign="right">
                  すべてのお知らせを見る
                </Box>
              </LinkList.Item>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
