import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

type Props = {};

export const Card: React.VFC<Props & BoxProps> = ({ children, ...props }) => {
  return (
    <Box bgColor="white" p={4} {...props} boxShadow="base">
      {children}
    </Box>
  );
};
