import { format as dateFormat } from "date-fns";
import i18next from "i18next";
import numeral from "numeral";
import { initReactI18next } from "react-i18next";

import { ja } from "~lib/i18n/ja";
import { withTax } from "~lib/index";

i18next.use(initReactI18next).init({
  interpolation: {
    format: (value, format) => {
      if (format === "yen") return value !== null && value !== undefined ? `${numeral(value).format("0,0")}円` : "";
      if (format === "yenWithTax")
        return value !== null && value !== undefined ? `${numeral(withTax(value)).format("0,0")}円` : "";
      if (format === "number") return value !== null && value !== undefined ? `${numeral(value).format("0,0")}` : "";
      if (value instanceof Date) return value && format ? dateFormat(value, format) : "";
      if (format === "area") return value !== null && value !== undefined ? numeral(value).format("0,0.00") : "";
      return value;
    },
  },
  lng: "ja",
  resources: {
    ja: {
      translation: ja,
    },
  },
});

export default i18next;
