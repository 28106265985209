import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useSelector as useReduxSelector, TypedUseSelectorHook } from "react-redux";
import { combineReducers } from "redux";

import auth from "~/store/auth";
import breadcrumbs from "~/store/breadcrumbs";
import error from "~/store/error";

const rootReducer = combineReducers({
  auth,
  breadcrumbs,
  error,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  middleware: getDefaultMiddleware({ serializableCheck: false }),
  reducer: rootReducer,
});

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
