import { service } from "~lib/i18n/ja/service";

export const my = {
  crumb: "マイページ",
  research: {
    orders: {
      "[code]": {
        inquiries: {
          complete: {
            title: "お部屋のお問い合わせ 完了",
          },
          title: "お部屋のお問い合わせ",
        },
        title: `${service.research.name} お申し込み詳細`,
      },
      crumb: service.research.nameVeryShort,
      title: service.research.name,
    },
    reports: {
      "[code]": {
        inquiries: {
          complete: {
            title: `お部屋のお問い合わせ`,
          },
          title: `お部屋のお問い合わせ`,
        },
        title: `${service.research.name} ご提案のお部屋`,
      },
    },
    rooms: {
      "[code]": {
        title: `${service.research.name} ご提案のお部屋`,
      },
    },
  },
  setting: {
    crumb: "設定",
    email: {
      title: "メールアドレス変更",
    },
    title: "設定",
  },
  title: "マイページ",
  viewing: {
    orders: {
      "[code]": {
        reports: {
          "[roomCode]": {
            title: `${service.research.name} レポート`,
          },
        },
        title: `${service.viewing.name} お申し込み詳細`,
      },
      crumb: service.viewing.nameVeryShort,
      title: service.viewing.name,
    },
  },
};
