/* eslint-disable sort-keys-fix/sort-keys-fix */
export const room = {
  no: "物件番号",
  title: "タイトル",
  comment: "コメント",
  registeredOn: "登録年月日",
  revisedOn: "変更年月日",
  buildingType: "物件種目",
  buildingTypeOptions: "マンション,アパート,タウン,間取り,文化住宅",
  newBuilt: "新築フラグ",
  adType: "広告転載区分",
  adTypeOptions: "広告可,一部可（インターネット）,一部可（チラシ・新聞広告）,広告可（但し要連絡）,不可",
  estateCompany: "不動産会社",
  chargeName: "物件問い合わせ先担当者",
  chargeTel: "物件担当者電話番号",
  chargeEmail: "物件担当者メールアドレス",
  chargeMemo: "自社管理欄",
  initialCostTotal: "初期費用概算",
  monthlyCostTotal: "月額費用概算",
  rent: "賃料",
  rentPerMeter: "㎡単価",
  rentPerTsubo: "坪単価",
  deposit: "敷金",
  keyMoney: "礼金",
  deposit2: "保証金",
  keyMoney2: "権利金",
  contractTermMonth: "契約期間",
  depreciationType: "償却コード",
  depreciationMonth: "償却月数",
  depreciationPercentage: "償却率",
  rentType: "建物賃貸借区分",
  rentTypeOptions: "普通借家権,定期借家権",
  rentTermYear: "建物賃貸借期間（年）",
  rentTermYearOptions: "1,2,3,4,5,6,7,8,9,10",
  rentTermMonth: "建物賃貸借期間（月）",
  rentTermMonthOptions: "0,1,2,3,4,5,6,7,8,9,10,11",
  renewableType: "建物賃貸借更新",
  renewableTypeOptions: "不可,条件付き可,可",
  areaMeasurementType: "面積計測方式",
  areaMeasurementTypeOptions: "壁芯,内法",
  area: "使用部分面積",
  balconyArea: "バルコニー(テラス)面積",
  prefecture: "都道府県名",
  addressLine1: "所在地名1",
  addressLine2: "所在地名2",
  addressLine3: "所在地名3",
  buildingName: "建物名",
  roomNumber: "部屋番号",
  cornerRoom: "角部屋",
  fullAddress: "住所",
  transportations: "交通手段",
  otherAddressLine: "その他所在地表示",
  otherTransportation: "その他交通手段",
  minutesForOtherTransportation: "交通（分）",
  metersForOtherTransportation: "交通（m）",
  layout: "間取タイプ",
  layoutOptions: "ワンルーム,K,DK,LK,LDK,SK,SDK,SLK,SLDK",
  numberOfRooms: "間取部屋数",
  otherForLayout: "間取りその他",
  builtOn: "築年月",
  structureType: "建物構造",
  structureTypeOptions: "木造,ブロック,鉄骨造,RC,SRC,PC,HPC,軽量鉄骨,その他",
  maxFloor: "地上階層",
  basementFloor: "地下階層",
  floor: "所在階",
  balconyDirection: "バルコニー方向",
  balconyDirectionOptions: "北,北東,東,南東,南,南西,西,北西",
  renovated1On: "増改築年月1",
  renovated1Memo: "増改築履歴1",
  renovated2On: "増改築年月2",
  renovated2Memo: "増改築履歴2",
  roomCount: "棟総戸数",
  managementAssociation: "管理組合",
  managementFee: "管理費",
  managementFeeTax: "うち管理費消費税",
  maintenanceFee: "共益費",
  renewalType: "更新区分",
  renewalTypeOptions: "新賃料,旧賃料",
  renewalFee: "更新料",
  brokerageFee: "仲介手数料",
  cleaningFee: "クリーニング費",
  noLumpSum: "その他一時金なし",
  lumpSum1Name: "その他一時金名称1",
  lumpSum1Price: "その他一時金名称1（金額）",
  lumpSum2Name: "その他一時金名称2",
  lumpSum2Price: "その他一時金名称2（金額）",
  otherMonthlyFeeName: "その他月額費名称",
  otherMonthlyFeePrice: "その他月額費名称金額",
  parking: "駐車場在否",
  parkingOptions: "空有,空無,近隣確保,無",
  parkingFee: "駐車場月額",
  parkingLowestPrice: "駐車場月額(最低値)",
  parkingHighestPrice: "駐車場月額(最高値)",
  status: "現況",
  statusOptions: "居住中,空家,賃貸中,未完成",
  movable: "入居時期",
  movableOptions: "即時,相談,期日指定,予定",
  movableOn: "入居年月",
  movableEml: "入居年月(上旬・中旬・下旬)",
  movableEmlOptions: "上旬,中旬,下旬",
  dealType: "取引態様",
  dealtOn: "媒介契約年月日",
  rewardType: "報酬形態",
  reward: "報酬",
  commissionPercentageForLender: "負担割合貸主",
  commissionPercentageForBorrower: "負担割合借主",
  commissionPercentageForOriginal: "配分割合元付",
  commissionPercentageForPlacement: "配分割合客付",
  useDistrictType: "用途地域",
  optimalUseType: "最適用途",
  approachStatus: "接道状況",
  insuranceObligation: "保険加入義務",
  insuranceName: "保険名称",
  insuranceFee: "保険料",
  insuranceYear: "保険期間",
  equipments: "設備・条件・住宅性能等",
  otherEquipments: "設備(フリースペース)",
  condition: "条件(フリースペース)",
  keyExchangeType: "鍵交換区分",
  keyExchangeTypeOptions: "鍵交換代金なし,鍵交換代金あり",
  keyExchangeFee: "鍵交換代金",
  note1: "備考1",
  note2: "備考2",
  note3: "備考3",
  note4: "備考4",
  latitude: "緯度",
  longitude: "経度",
};

export const roomTransportation = {
  railwayLineName: "沿線名",
  stationName: "駅名",
  minutesWalkFromStation: "駅より徒歩（分）",
  metersWalkFromStation: "駅より徒歩（m）",
  carFromStation: "駅より車",
  busFromStation: "駅よりバス",
  minutesFromBusStop: "バス停より徒歩（分）",
  metersFromBusStop: "バス停より徒歩（ｍ）",
  busLineName: "バス路線名",
  busStopName: "バス停名称",
};

export const roomSurrounding = {
  name: "周辺環境",
  meter: "距離",
  by: "時間（手段）",
  byOptions: "徒歩,車",
  minute: "時間（分）",
};
