import { Box, Container, Flex, Grid, GridItem } from "@chakra-ui/react";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";

import { Breadcrumbs } from "~compositions/Breadcrumbs";
import { PageTransition } from "~compositions/PageTransition";
import { BannerResearch } from "~compositions/banner/BannerResearch";
import { BannerViewing } from "~compositions/banner/BannerViewing";
import { BlogInformationWidget } from "~compositions/blog/BlogInformationWidget";
import { BlogSearch } from "~compositions/blog/BlogSearch";
import { BlogStationWidget } from "~compositions/blog/BlogStationWidget";
import { WidgetTitle } from "~compositions/blog/WidgetTitle";
import { staticPath } from "~generated/$path";
import { BlogLayoutFooter } from "~layouts/blog/BlogLayoutFooter";
import { FrontLayoutHeader } from "~layouts/front/FrontLayoutHeader";

export const BlogLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const isTop = pathname === "/blog";

  return (
    <Flex direction="column" flexGrow={1} w="full">
      <NextSeo
        openGraph={{
          images: [
            {
              height: 630,
              url: `${process.env.NEXT_PUBLIC_APP_HOST}/blog/ogp.jpg`,
              width: 1200,
            },
          ],
        }}
      />

      <Box as="main" flexGrow={1}>
        {isTop && children}
        {!isTop && (
          <>
            <FrontLayoutHeader isBlog />
            <Breadcrumbs />
            <Container maxW="container.xl" py={4}>
              <Grid columnGap={8} rowGap={20} templateColumns="repeat(4, 1fr)">
                <GridItem colSpan={{ base: 4, md: 3 }}>
                  <PageTransition>{children}</PageTransition>
                </GridItem>

                <GridItem colSpan={{ base: 4, md: 1 }}>
                  <WidgetTitle title={<img src={staticPath.blog.search_svg} />} />
                  <BlogSearch mt={4} />

                  <WidgetTitle mt={20} subTitle="人気の駅" title={<img src={staticPath.blog.ranking_svg} />} />

                  <BlogStationWidget limit={3} mt={4} />

                  <BlogInformationWidget mt={20} />

                  <WidgetTitle
                    mt={20}
                    subTitle={t("site.name") + "のサービス"}
                    title={<img src={staticPath.blog.service_svg} />}
                  />

                  <BannerResearch mt={4} mx="auto" />
                  {false && <BannerViewing mt={4} mx="auto" />}
                </GridItem>
              </Grid>
            </Container>
          </>
        )}
      </Box>
      <BlogLayoutFooter />
    </Flex>
  );
};
