import { useRouter } from "next/router";

import i18n from "~lib/i18n";

export const blogTitle = ({
  pathname,
  title,
}: {
  pathname?: string;
  root?: boolean;
  title?: string | null | undefined;
}) => {
  return `${title || pathnameToTitle(pathname)}${i18n.t("page.blog.titleSuffix")}`;
};

export const pathnameToTitle = (pathname: string | null | undefined, options = {}) => {
  if (!pathname) return "";
  const title = i18n.t(`page${pathname === "/" ? "" : pathname.replace(/\//g, ".")}.title`, {
    ...options,
    returnObjects: true,
  });

  if (typeof title === "string") return title;
  return null;
};

export const usePageTitle = () => {
  const { pathname } = useRouter();
  return pathnameToTitle(pathname);
};
