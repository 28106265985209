import { AspectRatio, Box, BoxProps, Divider } from "@chakra-ui/layout";
import { chakra, Stack } from "@chakra-ui/react";
import React from "react";

import { MarketPricesItem } from "./MarketPriceItem";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { NextLink } from "~/components/alias/NextLink";
import { NextImage } from "~elements/NextImage";
import { pagesPath } from "~generated/$path";
import { BlogParentStation } from "~lib/stations";
import { RailwayStationMarketPrice } from "~types/blog";

type Props = {
  no?: number;
  showMarketPrice?: boolean;
  station: BlogParentStation;
};

const findByLayout = (marketPrice: RailwayStationMarketPrice[], layout: string) => {
  return marketPrice.find((m) => m.layout === layout);
};

export const BlogStationWidgetItem: React.FC<Props & BoxProps> = ({
  station,
  no,
  showMarketPrice = false,
  ...props
}) => {
  const [marketPrice, setMarketPrice] = React.useState<RailwayStationMarketPrice[] | null>(null);

  React.useEffect(() => {
    if (showMarketPrice) {
      import(`~generated/market-prices/${station.id}.json`).then((json) => {
        setMarketPrice(json.default);
      });
    }
  }, [showMarketPrice, station.id]);

  const renderMarketPrice = () => {
    if (!marketPrice) return null;

    const price1 = findByLayout(marketPrice, "1K");
    const price2 = findByLayout(marketPrice, "1LDK");

    return (
      <Box mt={2}>
        <Stack divider={<Divider />} spacing={1}>
          {price1 && <MarketPricesItem layout={price1.layout} price={price1.rentAvg} />}
          {price2 && <MarketPricesItem layout={price2.layout} price={price2.rentAvg} />}
        </Stack>

        <NextLink href={pagesPath.blog.stations._id(station.id).$url()} passHref>
          <ChakraButton
            colorScheme="blackAlpha"
            component="a"
            display="inline-flex"
            h="auto"
            mt={4}
            p={1}
            textAlign="center"
            variant="outline"
            w="full"
          >
            <Box flexGrow={1} fontSize="sm">
              {station.name}駅の家賃相場を
              <br />
              詳しく見る
            </Box>
          </ChakraButton>
        </NextLink>
      </Box>
    );
  };

  return (
    <Box {...props}>
      <NextLink href={pagesPath.blog.stations._id(station.id).$url()} passHref>
        <chakra.a _hover={{ opacity: 0.8 }} display="block" flexGrow={1} pos="relative" pt={3}>
          <AspectRatio overflow="hidden" ratio={2 / 1} rounded="base">
            <NextImage layout="fill" objectFit="cover" src={station.images[0]} />
          </AspectRatio>

          {no && no <= 3 && (
            <Box left={0} pos="absolute" top={0}>
              <chakra.img src={`/blog/no${no}.png`} w={12} />
            </Box>
          )}

          <Box
            bg="rgba(0, 0, 0, 0.5)"
            bottom={0}
            color="white"
            fontWeight="bold"
            left={0}
            pos="absolute"
            px={2}
            py={1}
            right={0}
            textAlign="right"
          >
            {station.name}
          </Box>
        </chakra.a>
      </NextLink>

      {renderMarketPrice()}
    </Box>
  );
};
