import { createSlice } from "@reduxjs/toolkit";

import { BreadcrumbItem } from "~compositions/Breadcrumbs";

export type BreadcrumbsState = {
  items: BreadcrumbItem[] | null;
};

export const initialState: BreadcrumbsState = {
  items: null,
};

const slice = createSlice({
  initialState,
  name: "breadcrumbs",
  reducers: {
    setBreadcrumbItems(state, action) {
      state.items = action.payload;
    },
  },
});

export const { setBreadcrumbItems } = slice.actions;
export default slice.reducer;
