import defaultTheme from "@chakra-ui/theme";

const brand = {
  "100": "#fde6ba",
  "200": "#fbd68e",
  "300": "#fac661",
  "400": "#f8b634",
  "50": "#fef6e6",
  "500": "#f6a609",
  "600": "#c58507",
  "700": "#946405",
  "800": "#634303",
  "900": "#312102",
};

const pink = {
  "100": "#f5e1e6",
  "200": "#edc9d2",
  "300": "#e3abb8",
  "400": "#d8889b",
  "50": "#fbf3f5",
  "500": "#cc637c",
  "600": "#b1566a",
  "700": "#974959",
  "800": "#783a43",
  "900": "#5c2d32",
};

export const colors = {
  body: defaultTheme.colors.gray["800"],
  brand,
  emphasis: pink["500"],
  error: defaultTheme.colors.red["500"],
  muted: defaultTheme.colors.gray["500"],
  pink,
  primary: brand["400"],
};
