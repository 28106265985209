import { Box, BoxProps, Heading } from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/react";
import React from "react";

import { BlogStationWidgetItem } from "~compositions/blog/BlogStationWidgetItem";
import { ScrollHint } from "~elements/ScrollHint";
import { staticPath } from "~generated/$path";
import { popularStations } from "~lib/stations";

type Props = {
  horizontal?: boolean;
  limit: number;
  showMarketPrice?: boolean;
  showTitle?: boolean;
};

export const BlogStationWidget: React.VFC<Props & BoxProps> = ({
  limit,
  horizontal = false,
  showMarketPrice,
  showTitle = false,
  ...props
}) => {
  const render = () => {
    return popularStations
      .slice(0, limit)
      .map((station, index) => (
        <BlogStationWidgetItem
          css={{ scrollSnapAlign: horizontal ? "start" : undefined }}
          flexShrink={horizontal ? 0 : undefined}
          key={station.id}
          mt={!horizontal && index !== 0 ? 4 : 0}
          mx={horizontal ? 3 : undefined}
          no={index + 1}
          showMarketPrice={showMarketPrice}
          station={station}
          w={horizontal ? 48 : undefined}
        />
      ));
  };

  return (
    <Box {...props}>
      {showTitle && (
        <Heading alignItems="center" as="h3" display="flex" justifyContent="center" size="h3">
          <chakra.img mr={1} src={staticPath.blog.circle_yen_svg} verticalAlign="text-top" w={6} />
          東京 人気エリアの家賃相場
        </Heading>
      )}
      {horizontal && (
        <ScrollHint css={{ scrollSnapType: "x mandatory" }} display="flex" mt={4} overflowX="scroll">
          {render()}
        </ScrollHint>
      )}
      {!horizontal && render()}
    </Box>
  );
};
