import React from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "~/store";
import { setUser } from "~/store/auth";
import { useMeLazyQuery } from "~generated/graphql";
import { getAccessToken } from "~lib/auth";

export const useInitializeSession = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [meQuery] = useMeLazyQuery({
    onCompleted: ({ me }) => {
      dispatch(setUser(me));
    },
  });

  React.useEffect(() => {
    if (getAccessToken()) {
      if (!user) meQuery();
    } else {
      dispatch(setUser(null));
    }
  }, [dispatch, meQuery, user]);
};

export const useSession = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const logout = React.useCallback(() => {
    dispatch(setUser(null));
  }, [dispatch]);

  return { logout, user };
};
