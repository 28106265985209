import { Box, BoxProps, Center, chakra, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import nl2br from "react-nl2br";

import { LinkList } from "~compositions/LinkList";
import { Notification } from "~generated/graphql";

type Props = {
  handleClose?: () => void;
  notifications: Notification[] | undefined;
};

export const NotificationListItems: React.VFC<Props & BoxProps> = ({ notifications, ...props }) => {
  const { t } = useTranslation();

  if (!notifications) return null;

  if (notifications.length === 0) {
    return (
      <Box color="muted" py={4} textAlign="center">
        お知らせはありません
      </Box>
    );
  }

  return (
    <LinkList {...props}>
      {notifications.map((notification) => (
        <LinkList.Item href={notification.pathname} key={notification.id}>
          <Flex alignItems="center" flexGrow={1} pr={2}>
            <Center w={5}>
              {!notification.readAt && <chakra.span bg="primary" h={2} mr={2} rounded="full" w={2} />}
            </Center>
            <Box>
              <Box>{nl2br(notification.message)}</Box>
              <Box color="muted" fontSize="sm">
                {t("format.date.default", { date: new Date(notification.createdAt) })}
              </Box>
            </Box>
          </Flex>
        </LinkList.Item>
      ))}
    </LinkList>
  );
};
