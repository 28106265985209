import { Box, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList, useToast } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { NextLink } from "~/components/alias/NextLink";
import { Spinner } from "~elements/Spinner";
import { pagesPath } from "~generated/$path";
import { useConfirm } from "~hooks/useConfirm";
import { useSession } from "~hooks/useSession";

export const FrontLayoutAccountMenu: React.VFC = () => {
  const { user, logout } = useSession();
  const confirm = useConfirm();
  const router = useRouter();
  const toast = useToast();

  const onLogoutClick = async () => {
    if (await confirm("ログアウトしてもよろしいですか")) {
      logout();
      router.replace(pagesPath.$url());
      toast({ position: "top", status: "success", title: "ログアウトしました" });
    }
  };

  return (
    <Box>
      {/* https://github.com/chakra-ui/chakra-ui/issues/3440 */}
      <Menu computePositionOnMount isLazy>
        <MenuButton
          as={IconButton}
          backgroundColor="white"
          color="primary"
          h={8}
          icon={<FontAwesomeIcon icon="user" />}
          minH={8}
          minW={8}
          rounded="full"
          w={8}
        />
        <MenuList>
          {user === null && (
            <Link href={pagesPath.login.$url()} passHref>
              <MenuItem as="a" icon={<FontAwesomeIcon icon="sign-in-alt" />}>
                ログイン
              </MenuItem>
            </Link>
          )}
          {user && (
            <>
              <Box color="muted" ml={4}>
                {user.email}
              </Box>
              <MenuDivider />
              <NextLink href={pagesPath.my.$url()} passHref>
                <MenuItem as="a" icon={<FontAwesomeIcon icon="user" />}>
                  マイページ
                </MenuItem>
              </NextLink>
              <MenuItem icon={<FontAwesomeIcon icon="sign-out-alt" />} onClick={onLogoutClick}>
                ログアウト
              </MenuItem>
            </>
          )}
          {user === undefined && <Spinner />}
        </MenuList>
      </Menu>
    </Box>
  );
};
