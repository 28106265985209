import { UrlObject } from "url";

import { Box, BoxProps } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";

import { NextLink } from "~/components/alias/NextLink";

type Props = {
  href: UrlObject;
  icon: React.ReactChild;
};

export const AdminLayoutDrawerItem: React.VFC<Props & BoxProps> = ({ icon, href, children, ...props }) => {
  const router = useRouter();
  const active = router.pathname.startsWith(String(href.pathname));

  return (
    <NextLink href={href} passHref>
      <Box alignItems="center" as="a" display="block" px={2} {...props}>
        <Box
          _hover={{ background: "blackAlpha.200", color: "white" }}
          alignItems="center"
          bg={active ? "white" : "transparent"}
          color={active ? "primary" : "gray.600"}
          display="flex"
          fontSize="sm"
          fontWeight={active ? "bold" : "normal"}
          h="44px"
          px={3}
          py={2}
          rounded="xl"
        >
          <Box color={active ? "primary" : "gray.600"} mr={2} opacity={0.8}>
            {icon}
          </Box>
          {children}
        </Box>
      </Box>
    </NextLink>
  );
};
