import { Wrap, WrapItem } from "@chakra-ui/layout";
import React from "react";

import { BlogTag } from "~compositions/blog/BlogTag";
import { BlogTag as BlogTagType } from "~types/blog";

type Props = {
  tags: BlogTagType[];
  withLink?: boolean;
};

export const BlogTags: React.VFC<Props> = ({ tags, withLink }) => {
  return (
    <Wrap>
      {tags.map((tag) => (
        <WrapItem key={tag.id}>
          <BlogTag mt={2} tag={tag} withLink={withLink} />
        </WrapItem>
      ))}
    </Wrap>
  );
};
