import { estateCompany } from "~lib/i18n/ja/model/estateCompany";
import { room, roomTransportation, roomSurrounding } from "~lib/i18n/ja/model/room";
import { admin } from "~lib/i18n/ja/page/admin";
import { my } from "~lib/i18n/ja/page/my";
import { service } from "~lib/i18n/ja/service";

const reportRoom = {
  address: "住所",
  advanceRent: "前家賃",
  airConditioner: "冷暖房",
  area: "専有面積",
  bath: "バス・トイレ",
  buildingType: "建物種別",
  buildingTypeOptions: "マンション,アパート,一戸建て",
  builtOn: "築年月",
  cleaning: "クリーニング",
  comment: "コメント",
  commission: "仲介手数料",
  conditionForMovingIn: "入居条件",
  deposit: "敷金",
  direction: "向き",
  directionOptions: ["北", "北東", "東", "南東", "南", "南西", "西", "北西"].join(","),
  estateCompanyId: "担当不動産会社",
  fireInsuranceFee: "火災保険",
  floor: "階数",
  guarantorFee: "保証会社 費用",
  indoorEquipment: "屋内設備",
  initialCostTotal: "初期費用 概算",
  keyExchange: "鍵交換費用",
  kitchen: "キッチン",
  layout: "間取り",
  layoutOptions: "ワンルーム,1K,1DK,1LDK,2K,2DK,2LDK,3K,3DK,3LDK,4K,4DK,4LDK,5K以上",
  managementFee: "管理費・共益費",
  maxFloor: "階建",
  movable: "入居時期",
  otherFee: "その他費用",
  outdoorEquipment: "屋外設備",
  renewalFee: "更新料",
  renewalYear: "更新",
  rent: "家賃",
  rewards: "礼金",
  roomCount: "総戸数",
  security: "セキュリティ",
  status: "現状",
  statusOptions: "空家,居住中,不明",
  storage: "収納",
  structureType: "構造",
  structureTypeOptions: "鉄筋系,鉄骨系,木造,その他",
  title: "タイトル",
  tv: "テレビ・通信",
};

const userInfo = {
  birthday: "誕生日",
  country: "国籍",
  firstTime: "東京に住むのは初めてですか？",
  firstTimeOptions: "はい,いいえ",
  gender: "性別",
  genderOptions: "男性,女性",
  movingOn: "引越し日・引っ越し希望日",
  nickname: "ニックネーム",
  smoker: "タバコは吸いますか？",
  smokerOptions: "はい,いいえ",
  tel: "電話番号",
};

const order = {
  aasmState: "ステータス",
  amount: "ご請求金額(予定)",
  amountCaptured: "ご請求金額",
  code: "お申し込みID",
  createdAt: "お申し込み日時",
  orderPayment: {
    expiredAt: "決済期限",
    status: "決済ステータス",
  },
  paymentMethod: "お支払い方法",
  userId: "ユーザーID",
  ...userInfo,
};

export const ja = {
  blog: {
    informations: "お役立ち情報",
    name: "ドコニ！",
    nameLong: "ドコニ！ by ROOMiT",
  },
  enums: {
    payjpStatus: {
      captured: "決済確定済み",
      notCaptured: "決済確定前",
    },
  },
  format: {
    area: "{{area, area}}",
    date: {
      default: "{{- date, yyyy/MM/dd}}",
      yearMonth: "{{- date, yyyy年MM月}}",
    },
    datetime: {
      default: "{{- datetime, yyyy/MM/dd HH:mm}}",
    },
    number: "{{number, number}}",
    yen: "{{yen, yen}}",
    yenWithTax: "{{yen, yenWithTax}}",
  },

  model: {
    address: {
      addressLine1: "地名・番地",
      addressLine2: "建物名・部屋番号",
      cityName: "市区町村",
      postalCode: "郵便番号",
      prefectureCode: "都道府県",
    },
    chatRoom: {
      id: "チャットルームID",
      modelName: "お問い合わせ",
      userEmail: "メールアドレス",
    },
    common: {
      createdAt: "作成日時",
      updatedAt: "最終更新日時",
    },
    estateCompany,
    inquiry: {
      comment: "備考",
      createdAt: "お問い合わせ日時",
      email: "メールアドレス",
      id: "お問い合わせID",
      modelName: "お探しサービス 部屋問い合わせ",
      preferred: "連絡手段",
      tel: "電話番号",
      userId: "ユーザーID",
      userNickname: "ニックネーム",
    },
    notification: {
      modelName: "お知らせ",
    },
    order,
    orderPayment: { merchantPaymentId: "加盟店決済ID", paymentId: "決済番号" },
    researchOrder: {
      ...order,
      comment: "その他のご要望",
      commute: "通勤・通学",
      commuteOptions: "通勤,通学",
      commuteTime: "{{commute}}時間",
      commuteTimeOptions: "15分以内,16〜30分,31〜45分,46〜60分,61〜75分,76〜90分,希望なし",
      condition: "お部屋探しの条件",
      initialCost: "初期費用",
      modelName: "お探しサービス お申し込み",
      priorities: "重視するポイント",
      priorityOptions:
        "{{commute}}先から近い,駅に近い,家賃が安い,初期費用が安い,築浅,部屋が広い,セキュリティー,オシャレ,便利 (商業施設が多い),バス・トイレ別,ペット可",
      rent: "家賃",
      reportState: "ステータス",
      reportStateLabel: {
        draft: "お部屋 お探し中",
        published: "お部屋探し完了",
      },
      stationId: "{{commute}}先の最寄り駅",
      transportation: "交通費",
    },
    researchReport: {
      aasmState: "ステータス",
      aasmStateLabel: {
        draft: "下書き",
        published: "公開",
      },
      code: "お部屋探し レポートID",
      comment: "全体のコメント",
      id: "レポートID",
      modelName: "お部屋探しサービス レポート",
      publishedAt: "公開日時",
      readAt: "閲覧日時",
      title: "タイトル",
    },
    researchReportRoom: {
      ...reportRoom,
      layoutMedias: "間取り図",
      mainMedias: "メイン画像",
      minutesFromStation: "最寄り駅から徒歩",
      otherMedias: "その他写真",
      stationId: "最寄り駅",
      viewable: "内見代行可",
      viewableLabel: {
        false: "内見代行不可",
        true: "内見代行可",
      },
    },
    room,
    roomSurrounding,
    roomTransportation,
    sampleResearchReport: {
      persona: "ペルソナ",
    },

    user: {
      admin: "管理ユーザー",
      createdAt: "登録日時",
      email: "メールアドレス",
      id: "ユーザーID",
      modelName: "ユーザー",
      nickname: "ニックネーム",
    },

    verification: {
      comment: "コメント",
      modelName: "不動産会社への確認",
    },

    viewingOrder: {
      comment: "備考",
      doneRoomCount: "完了レポート",
      modelName: "内見代行 お申し込み",
      url: "物件のURL",
      ...order,
      ...userInfo,
    },

    viewingReportRoom: {
      ...reportRoom,
      aasmStateLabel: {
        disabled: "内見不可",
        draft: "下書き",
        published: "公開",
      },
      bathMedias: "お風呂",
      bicycleParkingMedias: "駐輪場",
      ceilingHeight: "天井の高さ",
      convenienceStore: "最寄りのコンビニ",
      curtainRail: "カーテンレール",
      disabledReason: "内見不可の理由",
      doorMedias: "ドア",
      entranceMedias: "エントランス",
      facilityMedias: "設備",
      hallwayMedias: "廊下",
      hospital: "最寄りの病院",
      kitchenMedias: "キッチン",
      layoutMedias: "間取り図",
      mainMedias: "メイン画像",
      minutesFromStation: "最寄り駅から徒歩",
      modelName: "内見代行レポート",
      neighbors: "近隣住民",
      outlet: "コンセント",
      outsideFromEntranceMedias: "エントランスから見た外",
      outsideFromSharedHallwayMedias: "共用廊下から見た外",
      outsideFromVerandaMedias: "ベランダから見た外",
      park: "最寄りの公園",
      parkingMedias: "駐車場",
      policeOffice: "最寄りの警察",
      publishedAt: "公開日時",
      roomMedias: "部屋",
      school: "最寄りの学校",
      sharedHallwayMedias: "共用廊下",
      shoesRackMedias: "下駄箱",
      stationId: "最寄り駅",
      storageMedias: "収納",
      supermarket: "最寄りのスーパー",
      surroundings: "周辺環境",
      theta360Url: "THETA 360.bizのURL",
      tiltAngle: "床の傾斜角度",
      toiletMedias: "トイレ",
      traffic: "人・車通り",
      trashAreaMedias: "ゴミ置き場",
      verandaMedias: "ベランダ",
      wallThickness: "壁の厚さ",
      waterHeaterMedias: "給湯器",
      waterPressure: "水圧",
    },
  },

  page: {
    "404": {
      title: "ページが見つかりません",
    },
    "500": {
      title: "エラーが発生しました",
    },
    admin,
    blog: {
      articles: {
        crumb: "記事",
        page: {
          "[page]": {
            title: "すべての記事 ページ{{page}}",
          },
        },
        preview: {
          crumb: "プレビュー",
          title: "記事プレビュー",
        },
        tags: {
          "[tag]": {
            page: {
              "[page]": {
                title: "{{tag}} ページ{{page}}",
              },
            },
          },
          crumb: "タグ",
          title: "タグ",
        },
        title: "すべての記事",
      },
      crumb: "ドコニ！",
      stations: {
        "[id]": {
          reviews: {
            crumb: "口コミ",
          },
        },
        crumb: "駅",
        title: "すべての駅",
      },
      title: "東京周辺の最寄り駅 口コミサイト ドコニ！ by ROOMiT",
      titleSuffix: "ドコニ！ by ROOMiT",
    },
    contact: {
      login: {
        title: "ログイン",
      },
      title: "お問い合わせ",
    },
    faq: {
      title: "FAQ・よくある質問",
    },

    info: {
      law: {
        title: "特定商取引法に基づく表記",
      },
      "privacy-policy": {
        title: "プライバシーポリシー",
      },
      "terms-of-service": {
        title: "利用規約",
      },
    },

    login: {
      title: "ログイン",
    },

    my,

    "panorama-viewer": { title: "パノラマビューアー" },
    research: {
      order: {
        complete: {
          title: `${service.research.name} お申し込み 完了`,
        },
        login: {
          title: "ログイン",
        },
        payment: {
          paypay: {
            callback: {
              title: `${service.research.name} お申し込み PayPayお支払い`,
            },
            resume: {
              title: `${service.research.name} お申し込み PayPayお支払い`,
            },
          },
        },
        title: `${service.research.name} お申し込み`,
      },
      "sample-reports": {
        "[code]": {
          title: `${service.research.name} サンプルレポート`,
        },
        rooms: { "[code]": { title: `${service.research.name} サンプルレポート お部屋` } },
      },
      title: service.research.name,
    },

    title: "お部屋探しを徹底サポート",

    titleSuffix: "ROOMiT （ルーミット)",
    tokyo: { title: "初めて東京で通勤・通学する人のお部屋探しを徹底サポート" },
    "verify-unconfirmed-email-token": { title: "メールアドレス変更" },

    viewing: {
      order: {
        complete: {
          title: `${service.viewing.name} お申し込み 完了`,
        },
        login: {
          title: "ログイン",
        },
        title: `${service.viewing.name} お申し込み`,
      },
      title: service.viewing.name,
    },
  },

  paymentMethods: {
    free: "無料",
    payjp: "クレジットカード",
    paypay: "PayPay",
  },

  service,

  site: {
    name: "ROOMiT",
    nameJa: "ルーミット",
    nameLong: "ROOMiT （ルーミット)",
  },
};
