import { BoxProps } from "@chakra-ui/react";
import React from "react";

import { Banner } from "~compositions/banner/Banner";
import { NextImage } from "~elements/NextImage";
import { pagesPath } from "~generated/$path";

export const BannerResearch: React.VFC<BoxProps> = (props) => {
  return (
    <Banner href={pagesPath.research.$url()} {...props}>
      <NextImage height={280} src="/research/banner.jpg" width={280} />
    </Banner>
  );
};
