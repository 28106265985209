import { Flex, HStack } from "@chakra-ui/react";
import Image from "next/image";
import React from "react";

import { NextLink } from "~/components/alias/NextLink";
import { BlogSearch } from "~compositions/blog/BlogSearch";
import { pagesPath } from "~generated/$path";
import { FrontLayoutAccountMenu } from "~layouts/front/FrontLayoutAccountMenu";
import { FrontLayoutNotifications } from "~layouts/front/FrontLayoutNotifications";

export const FrontLayoutHeader: React.VFC<{
  isBlog?: boolean;
}> = ({ isBlog = false }) => {
  return (
    <Flex
      alignItems="center"
      as="header"
      bg="primary"
      flexShrink={0}
      h={12}
      pos="sticky"
      px={4}
      top={0}
      zIndex="banner"
    >
      <NextLink href={pagesPath.$url()}>
        <a>
          <Image height={40} src="/logo_white.svg" width={170} />
        </a>
      </NextLink>

      {!isBlog && (
        <HStack ml="auto">
          <FrontLayoutNotifications />
          <FrontLayoutAccountMenu />
        </HStack>
      )}
      {isBlog && <BlogSearch ml="auto" noLabel />}
    </Flex>
  );
};
