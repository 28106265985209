import axios from "axios";

const cmsAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_MICRO_CMS_API_URL,
  headers: {
    "X-API-KEY": process.env.NEXT_PUBLIC_MICRO_CMS_KEY || "",
  },
});

export default cmsAxios;
