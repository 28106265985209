import { Box, BoxProps, SimpleGrid } from "@chakra-ui/layout";
import range from "lodash/range";
import React from "react";
import { useQuery } from "react-query";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { NextLink } from "~/components/alias/NextLink";
import { BlogArticleCard } from "~compositions/blog/BlogArticleCard";
import { WidgetItemSkelton } from "~compositions/blog/WidgetItemSkelton";
import { WidgetTitle } from "~compositions/blog/WidgetTitle";
import { pagesPath, staticPath } from "~generated/$path";
import cmsAxios from "~lib/cmsAxios";
import { BlogArticle, CMSListResponse } from "~types/blog";

type Props = {
  horizontal?: boolean;
};

export const BlogInformationWidget: React.VFC<Props & BoxProps> = ({ horizontal = false, ...props }) => {
  const { data }: { data: CMSListResponse<BlogArticle> | undefined } = useQuery("latestInformations", () =>
    cmsAxios("/articles?limit=3&filters=tags[contains]tips").then((res) => res.data)
  );

  const informations = data?.contents;

  if (!informations) {
    return (
      <div>
        {range(3).map((i) => (
          <WidgetItemSkelton key={i} />
        ))}
      </div>
    );
  }

  if (informations.length === 0) return null;

  return (
    <Box {...props}>
      <NextLink href={pagesPath.blog.articles.tags._tag("tips").page._page(1).$url()} passHref>
        <a>
          <WidgetTitle subTitle="お役立ち情報" title={<img src={staticPath.blog.information_svg} />} />
        </a>
      </NextLink>
      <SimpleGrid columns={horizontal ? { sm: 3 } : 1} gap={4} mt={4}>
        {informations.map((information) => (
          <BlogArticleCard article={information} key={information.id} />
        ))}
      </SimpleGrid>

      <Box mt={4} textAlign="right">
        <NextLink href={pagesPath.blog.articles.tags._tag("tips").page._page(1).$url()} passHref>
          <ChakraButton colorScheme="blackAlpha" component="a" variant="outline">
            もっと見る
          </ChakraButton>
        </NextLink>
      </Box>
    </Box>
  );
};
