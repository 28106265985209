/*
初期化前: case1 -> loading

トップページ
  未ログイン: case2 -> render
  一般ユーザー: case3 -> warning
  管理者: case4 -> redirect

トップページ以外
  未ログイン: case5 -> redirect
  一般ユーザー: case6 -> redirect
  管理者: case7 -> render
*/

import { Box, Center, useToast } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { ChakraLink } from "~/components/alias/ChakraLink";
import { NextLink } from "~/components/alias/NextLink";
import { RootState } from "~/store";
import { PageTransition } from "~compositions/PageTransition";
import { Spinner } from "~elements/Spinner";
import { pagesPath } from "~generated/$path";
import { useSession } from "~hooks/useSession";
import { AdminLayoutDrawer } from "~layouts/admin/AdminLayoutDrawer";
import { AdminLayoutHeader } from "~layouts/admin/AdminLayoutHeader";
import { AdminLayoutLogo } from "~layouts/admin/AdminLayoutLogo";

const isTop = (pathname: string) => pathname === "/admin";

const Page: React.FC = ({ children }) => {
  return (
    <Box display="flex" minH="100vh" w="full">
      <Box
        bg="primary"
        bottom={0}
        display={{ base: "none", sm: "block" }}
        h="100vh"
        left={0}
        pos="fixed"
        top={0}
        zIndex="sticky"
      >
        <AdminLayoutLogo />
        <AdminLayoutDrawer w="240px" />
      </Box>

      <Box as="main" display="flex" flexDirection="column" id="app" pl={{ sm: "240px" }} w="full">
        <AdminLayoutHeader />
        <PageTransition>{children}</PageTransition>
      </Box>
    </Box>
  );
};

export const AdminLayout: React.FC = ({ children }) => {
  const router = useRouter();
  const { logout } = useSession();
  const toast = useToast();

  const { user } = useSelector((state: RootState) => state.auth);

  React.useEffect(() => {
    // case4
    if (isTop(router.pathname) && user && user?.admin) {
      router.replace("/admin/dashboard");
      return;
    }

    // case5 case6
    if (!isTop(router.pathname)) {
      if (user === null || user?.admin === false) {
        router.replace("/admin");
        return;
      }
    }
  }, [router, user]);

  const onLogoutClick = async () => {
    if (await confirm("ログアウトしてもよろしいですか")) {
      logout();
      router.replace("/admin");
      toast({ position: "top", status: "success", title: "ログアウトしました" });
    }
  };

  const render = () => {
    if (isTop(router.pathname)) {
      if (user === null) {
        // case2
        return <>{children}</>;
      } else if (user && user?.admin === false) {
        // case 3
        return (
          <Center flexGrow={1} h="100vh">
            <div>
              <div>管理者のみアクセスできます</div>
              <ChakraButton
                colorScheme="blackAlpha"
                leftIcon={<FontAwesomeIcon icon="sign-out-alt" />}
                mt={8}
                onClick={onLogoutClick}
                variant="outline"
                w="full"
              >
                ログアウト
              </ChakraButton>

              <Box mt={8} textAlign="center">
                <NextLink href={pagesPath.$url()} passHref>
                  <ChakraLink>トップページへ</ChakraLink>
                </NextLink>
              </Box>
            </div>
          </Center>
        );
      }
    } else {
      // case 7
      if (user && user?.admin) return <Page>{children}</Page>;
    }

    // case 1
    return <Spinner centered />;
  };

  return (
    <>
      <Head>
        <meta content="noindex" name="robots" />
      </Head>
      {render()}
    </>
  );
};
