import "~/styles/index.scss";
import "~lib/i18n";
import "~lib/font-awesome";
import "~lib/react-datepicker";

import { ApolloProvider } from "@apollo/client";
import { Center, ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { AnimatePresence } from "framer-motion";
import compact from "lodash/compact";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider, useDispatch, useSelector } from "react-redux";

import { RootState, store } from "~/store";
import { setErrorMessage } from "~/store/error";
import theme from "~/theme";
import apolloClient from "~graphql/client";
import { useConfirm } from "~hooks/useConfirm";
import { useInitializeSession } from "~hooks/useSession";
import { useSimpleAuth } from "~hooks/useSimpleAuth";
import { ProgressBar } from "~layouts/ProgressBar";
import { SimpleAuth } from "~layouts/SimpleAuth";
import { AdminLayout } from "~layouts/admin/AdminLayout";
import { BlogLayout } from "~layouts/blog/BlogLayout";
import { FrontLayout } from "~layouts/front/FrontLayout";
import { PanoramaLayout } from "~layouts/panorama/PanoramaLayout";
import { pageview } from "~lib/gtag";
import { pathnameToTitle } from "~lib/headUtils";

const reactQueryClient = new QueryClient();

export const Fallback: React.VFC = () => {
  return <Center>申し訳ありません。エラーが発生しました</Center>;
};

function Page({ Component, pageProps }: AppProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { errorMessage } = useSelector((state: RootState) => state.error);
  const { simpleAuthed } = useSimpleAuth();

  useInitializeSession();

  let Layout = null;
  let titleSuffix = "";
  if (router.pathname.startsWith("/admin")) {
    Layout = AdminLayout;
    titleSuffix = t("page.admin.titleSuffix");
  } else if (router.pathname.startsWith("/panorama-viewer")) {
    Layout = PanoramaLayout;
    titleSuffix = t("page.titleSuffix");
  } else if (router.pathname.startsWith("/blog")) {
    Layout = BlogLayout;
    titleSuffix = t("page.blog.titleSuffix");
  } else {
    Layout = FrontLayout;
    titleSuffix = t("page.titleSuffix");
  }

  React.useEffect(() => {
    if (process.env.NEXT_PUBLIC_TAG_MANAGER_ID) {
      TagManager.initialize({
        auth: process.env.NEXT_PUBLIC_TAG_MANAGER_AUTH,
        gtmId: process.env.NEXT_PUBLIC_TAG_MANAGER_ID,
        preview: process.env.NEXT_PUBLIC_TAG_MANAGER_PREVIEW,
      });
    }
  }, []);

  React.useEffect(() => {
    if (!errorMessage) return;

    (async () => {
      await confirm(errorMessage, { okOnly: true, title: "エラー" });
      dispatch(setErrorMessage(null));
    })();
  }, [confirm, dispatch, errorMessage]);

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  if (simpleAuthed === undefined) {
    return (
      <Head>
        <meta content="noindex" name="robots" />
      </Head>
    );
  } else if (simpleAuthed === false) {
    return (
      <>
        <Head>
          <meta content="noindex" name="robots" />
        </Head>
        <SimpleAuth />
      </>
    );
  }

  return (
    <>
      <Head>
        <link href="/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180" />
        <link href="/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
        <link href="/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />
        <link href="/site.webmanifest" rel="manifest" />
        <link color="#5bbad5" href="/safari-pinned-tab.svg" rel="mask-icon" />
        <meta content="width=device-width,initial-scale=1" name="viewport" />
        <meta content="#ffffff" name="msapplication-TileColor" />
        <meta content="#ffffff" name="theme-color" />
      </Head>

      <DefaultSeo
        noindex={process.env.NEXT_PUBLIC_NO_INDEX === "1"}
        openGraph={{
          images: [
            {
              height: 630,
              url: `${process.env.NEXT_PUBLIC_APP_HOST}/ogp.jpg`,
              width: 1200,
            },
          ],
          locale: "ja_JP",
          site_name: t("site.nameLong"),
          type: "article",
          url: `${process.env.NEXT_PUBLIC_APP_HOST}${router.asPath}`,
        }}
        title={compact([pathnameToTitle(router.pathname), titleSuffix]).join(" | ")}
        twitter={{
          cardType: "summary",
        }}
      />

      <Layout>
        <Component {...pageProps} key={router.route} />
      </Layout>
    </>
  );
}

function MyApp(props: AppProps) {
  const router = useRouter();

  return (
    <AnimatePresence exitBeforeEnter>
      <Sentry.ErrorBoundary fallback={<Fallback />}>
        <ChakraProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <QueryClientProvider client={reactQueryClient}>
              <Provider store={store}>
                <Page {...props} key={router.asPath} />
                <ProgressBar />
              </Provider>
            </QueryClientProvider>
          </ApolloProvider>
        </ChakraProvider>
      </Sentry.ErrorBoundary>
    </AnimatePresence>
  );
}
export default MyApp;
