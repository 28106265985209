import { Box, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList, useToast } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React from "react";

import { pagesPath } from "~generated/$path";
import { useConfirm } from "~hooks/useConfirm";
import { useSession } from "~hooks/useSession";

export const AdminLayoutAccountMenu: React.VFC = () => {
  const { user, logout } = useSession();
  const confirm = useConfirm();
  const router = useRouter();
  const toast = useToast();

  const onLogoutClick = async () => {
    if (await confirm("ログアウトしてもよろしいですか")) {
      logout();
      router.replace(pagesPath.admin.$url());
      toast({ position: "top", status: "success", title: "ログアウトしました" });
    }
  };

  if (!user) return null;

  return (
    <Box ml="auto">
      {/* https://github.com/chakra-ui/chakra-ui/issues/3440 */}
      <Menu computePositionOnMount isLazy>
        <MenuButton
          as={IconButton}
          colorScheme="blackAlpha"
          h={8}
          icon={<FontAwesomeIcon icon="user" />}
          minH={8}
          minW={8}
          rounded="full"
          w={8}
        />
        <MenuList rootProps={{ style: { right: 0 } }} zIndex={99999}>
          <Box color="muted" ml={4}>
            {user.email}
          </Box>
          <MenuDivider />
          <MenuItem icon={<FontAwesomeIcon icon="sign-out-alt" />} onClick={onLogoutClick}>
            ログアウト
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
