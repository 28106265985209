import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { ThemeProvider, useChakra } from "@chakra-ui/system";
import React from "react";
import ReactDOM from "react-dom";
import nl2br from "react-nl2br";

type OptionProps = {
  cancelLabel?: string;
  okLabel?: string;
  okOnly?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  title?: string;
};

const ConfirmDialog: React.FC<{ message: string | React.ReactNode; theme: any } & OptionProps> = ({
  cancelLabel = "キャンセル",
  okLabel = "OK",
  okOnly = false,
  onCancel,
  onOk,
  title = "確認",
  message,
  theme,
}) => {
  const [open, setOpen] = React.useState(false);
  const cancelRef = React.useRef(null);

  React.useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AlertDialog
        autoFocus={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
        isOpen={open}
        leastDestructiveRef={cancelRef}
        onClose={() => setOpen(false)}
        size="xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{title}</AlertDialogHeader>
            <AlertDialogBody>{typeof message === "string" ? nl2br(message) : message}</AlertDialogBody>
            <AlertDialogFooter>
              {!okOnly && (
                <Button data-test="confirm-modal-cancel" onClick={onCancel} variant="outline" width={120}>
                  {cancelLabel}
                </Button>
              )}
              <Button colorScheme="brand" data-test="confirm-modal-ok" ml={2} onClick={onOk} width={120}>
                {okLabel}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ThemeProvider>
  );
};

const id = "confirm-wrapper";

export const useConfirm = () => {
  const el = React.useRef<HTMLDivElement | null>(null);
  const { theme } = useChakra();

  const cleanUp = () => {
    if (el.current) {
      ReactDOM.unmountComponentAtNode(el.current);
      setTimeout(() => el?.current?.remove());
    }
  };

  React.useEffect(() => {
    return cleanUp;
  }, []);

  const confirm = (message: string | React.ReactNode, option?: OptionProps) => {
    if (document.getElementById(id)) return;

    const div = document.body.appendChild(document.createElement("div"));
    div.id = id;
    el.current = div;

    return new Promise((resolve) => {
      ReactDOM.render(
        <ConfirmDialog
          message={message}
          {...option}
          onCancel={() => {
            cleanUp();
            resolve(false);
          }}
          onOk={() => {
            cleanUp();
            resolve(true);
          }}
          theme={theme}
        />,
        div
      );
    });
  };

  return confirm;
};
