import { motion } from "framer-motion";
import React from "react";

export const PageTransition: React.FC = ({ children }) => {
  return (
    <motion.div
      animate={{ y: 0 }}
      initial={{ y: -16 }}
      style={{ display: "flex", flexDirection: "column", flexGrow: 1, height: "100%" }}
      transition={{ ease: "easeOut" }}
    >
      {children}
    </motion.div>
  );
};
