import { BoxProps } from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  price: number;
};

export const Price: React.VFC<Props & BoxProps> = ({ price, ...props }) => {
  const { t } = useTranslation();

  return (
    <chakra.span whiteSpace="nowrap" {...props}>
      <span>{price ? t("format.yen", { yen: price }) : "---"}</span>
    </chakra.span>
  );
};
