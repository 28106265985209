import { Box, Flex } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/react";
import React from "react";

type Props = {};

export const WidgetItemSkelton: React.VFC<Props> = () => {
  return (
    <Flex h={20} mb={4}>
      <Box w={32}>
        <Skeleton variant="rect" />
      </Box>
      <Flex flexDirection="column" flexGrow={1} pl={2}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Flex>
    </Flex>
  );
};
