import { Badge, Box, Flex } from "@chakra-ui/layout";
import React from "react";

import { Price } from "~elements/Price";

export const MarketPricesItem: React.VFC<{ layout: string; price: number }> = ({ layout, price }) => {
  return (
    <Flex alignItems="center">
      <div>
        <Badge fontSize="xs" rounded="full" textAlign="center" w={12}>
          {layout}
        </Badge>
      </div>
      <Box flexGrow={1}>
        <Price display="block" price={price} textAlign="right" />
      </Box>
    </Flex>
  );
};
